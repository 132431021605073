@font-face {
    font-family: "AvenirNextLTPro";
    font-weight: normal;
    src: url("../fonts/AvenirNextLTProRegular/font.woff2") format("woff2"),
         url("../fonts/AvenirNextLTProRegular/font.woff") format("woff");
}
@font-face {
    font-family: "AvenirNextLTPro";
    font-weight: bold;
    src: url("../fonts/AvenirNextLTProBold/font.woff2") format("woff2"),
         url("../fonts/AvenirNextLTProBold/font.woff") format("woff");
}
