//
//  Common
//  _____________________________________________

.filter {
    &.block {
        margin-bottom: 0;
    }

    &-no-options {
        display: none;
    }

    &-options {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-gap: 15px;

        &-item {
            position: relative;
            background-color: $cblys__slightgray;
            display: none;

            &:nth-child(-n+5) {
                display: block;
            }

            &:last-child {
                display: block;
            }

            &.show-all {
                display: block;
            }

            &[data-filter-name="in_stock_stores"] {
                background-color: $cblys__re-beige;
            }
        }

        .search {
            /* Client: search is unnecessary */
            display: none;
            margin: 5px 0;
        }

        input.filter-search {
            height: 36px;
            border: none;
            margin: 0;
        }

        &-title {
            font-size: 17px;
            color: $cblys__dark-blue;
            cursor: pointer;
            margin: 10px;
            padding-right: 15px;
            display: flex;
            white-space: nowrap;
            position: relative;

            .arrow {
                display: block;
                width: 30px;
                height: 43px;
                position: absolute;
                right: -10px;
                top: -10px;

                &:after {
                    @include material-icon($icon-down);
                    top: 11px;
                    right: 4px;
                    position: absolute;
                }
            }
        }

        &-item.active &-title .arrow:after {
            @include material-icon($icon-up);
        }

        &-toggle {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            color: $cblys__dark-blue;
            text-decoration: underline !important;

            .hide-filters {
                display: none;
            }
        }

        &-content {
            display: none;
            margin: 0;
            position: absolute;
            z-index: 6;
            background-color: $cblys__slightgray;
            top: 46px;
            width: 100%;

            .filters {
                padding: 10px;
            }

            .items {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                margin: 0;
                padding: 0;
                list-style: none;
            }

            .item > a {
                display: flex;
                align-items: center;
                position: relative;

                label {
                    min-height: 24px;
                    color: $cblys__dark-blue;
                    font-size: 14px;
                    line-height: 21px;
                }

                .count {
                    color: $cblys__darkgray;

                    &:before {
                        content: ' (';
                    }

                    &:after {
                        content: ')';
                    }

                    .filter-count-label {
                        display: none;
                    }
                }
            }

            .item {
                margin: 4px 0;

                input[type="checkbox"] {
                    position: absolute;
                    opacity: 0;

                    & + label {
                        position: relative;
                        cursor: pointer;
                        padding: 0 0 0 32px;
                        width: 100%;
                    }

                    & + label:before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 24px;
                        height: 24px;
                        background-image: svg-uri('<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill="#{$cblys__dark-blue}" d="M5 3L19 3C20.1 3 21 3.9 21 5L21 19C21 20.1 20.1 21 19 21L5 21C3.9 21 3 20.1 3 19L3 5C3 3.9 3.9 3 5 3ZM19 19L19 5L5 5L5 19H19Z"/></svg>');
                    }

                    &:checked + label:after {
                        content: '';
                        background-image: svg-uri('<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M5 3L19 3C20.1 3 21 3.9 21 5L21 19C21 20.1 20.1 21 19 21L5 21C3.9 21 3 20.1 3 19L3 5C3 3.9 3.9 3 5 3ZM9.3 16.29C9.69 16.68 10.32 16.68 10.71 16.29L18.29 8.7C18.68 8.31 18.68 7.68 18.29 7.29C17.9 6.9 17.27 6.9 16.88 7.29L10 14.17L7.12 11.29C6.73 10.9 6.1 10.9 5.71 11.29C5.52275 11.4768 5.41751 11.7305 5.41751 11.995C5.41751 12.2595 5.52275 12.5132 5.71 12.7L9.3 16.29Z" /></svg>');
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 24px;
                        height: 24px;
                    }

                    &:disabled + label {
                        color: $cblys__slightgray;
                        cursor: auto;
                    }

                    &:disabled + label:before {
                        background-image: svg-uri('<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill="#{$cblys__slightgray}" d="M5 3L19 3C20.1 3 21 3.9 21 5L21 19C21 20.1 20.1 21 19 21L5 21C3.9 21 3 20.1 3 19L3 5C3 3.9 3.9 3 5 3ZM19 19L19 5L5 5L5 19H19Z"/></svg>');
                    }
                }
            }

            .actions {
                .show-more,
                .show-less {
                    display: block;
                    margin: 5px 0 0 32px;
                    font-weight: 700;
                    font-size: 16px;
                    text-decoration: underline;
                    cursor: pointer;
                }

                .filters-apply {
                    display: flex;
                    justify-content: center;
                    border: 1px solid $cblys__dark-blue;
                    margin: 15px 0 5px 0;
                    font-size: 18px;
                    padding: 5px;
                    cursor: pointer;
                }
            }
        }
    }

    &-current {
        margin: 30px 0 0 0;
        display: flex;

        &-subtitle {
            font-size: 16px;
            white-space: nowrap;
            color: $cblys__dark-blue;
            padding: 5px 10px 0 0;
        }

        .items {
            flex-grow: 1;
            display: flex;
            flex-wrap: wrap;
            margin: 0;
            padding: 0;

            @include max-screen($screen__m) {
                margin: 0 1rem 0 0;
            }
        }

        .item {
            position: relative;
            display: flex;
            background: $cblys__slightgray;
            padding: 5px 7px 5px 12px;
            margin: 0 0 12px 10px;
            border-radius: $cblys__border-radius;
            font-size: 16px;

            .action.remove {
                padding: 0 0 0 10px;
                display: flex;
                align-items: center;

                &:after {
                    @include material-icon($icon-clear);
                    font-size: 16px;
                }

                span {
                    display: none;
                }
            }
        }

        .filter-label {
            margin-right: .5rem;
            font-weight: $font-weight__bold;
        }
    }
}

//
//  Mobile
//  _____________________________________________
@include max-screen($screen__m) {
    body {
        &.filter-active {
            .page-header {
                position: fixed;
                height: 65px;
                width: 100%;
                z-index: 5;
                border: none;
            }

            .page-main {
                margin-top: 65px;
            }

            .page-wrapper {
                overflow: hidden;
                height: 100vh;
                position: fixed;
            }
        }
    }

    .filter {
        &-options {
            display: none;
        }

        &:not(.active) &-title {
            margin: 0 1rem;
            padding: 10px;
            border: 1px solid $cblys__dark-blue;
            background-color: $cblys__slightgray;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
            font-size: 16px;

            &:before {
                @include material-icon($icon-tune);
                margin-right: 10px;
            }

            .active-text {
                display: none;
            }
        }

        &.active {
            position: relative;
            visibility: visible;
            z-index: 99;

            .filter-options-item:last-child {
                margin-bottom: $indent__xl;
            }

            .filter-title {
                border: none;
                height: 50px;
                left: 0;
                position: fixed;
                top: 64px;
                z-index: 10;
                background-color: $cblys__lightgray;
                max-width: 290px;
                width: 100%;

                strong {
                    display: flex;
                    justify-content: space-between;
                    height: 100%;
                    align-items: center;
                    padding: 1rem;
                    font-size: 18px;
                    position: relative;
                    z-index: 20;

                    &:after {
                        @include material-icon($icon-clear);
                        color: $cblys__darkergray;
                    }

                    &:before {
                        position: fixed;
                        top: 64px;
                        right: 0;
                        bottom: 0;
                        width: calc(100% - 290px);
                        background-color: rgba(0, 0, 0, .4);
                        z-index: 1;
                        content: '.';
                        color: transparent;
                    }
                }

                .active-text {
                    display: inline;
                }

                .not-active-text {
                    display: none;
                }
            }

            .filter-subtitle {
                display: none;
            }

            .filter-options {
                background-color: $cblys__lightgray;
                bottom: 0;
                display: block;
                left: 0;
                overflow: scroll;
                position: fixed;
                top: 114px;
                z-index: 10;
                max-width: 290px;
                width: 100%;
            }

            .filter-options-title {
                margin: 0;
                padding: 15px 30px 15px 15px;
                border: 1px solid $cblys__lightgray;
                font-size: 16px;
                font-weight: 400;
                justify-content: space-between;

                .arrow {
                    right: 0px;
                    top: 3px;
                }

                &:after {
                    right: 5px;
                    top: 15px;
                }

                &:before {
                    content: attr(data-count);
                    order: 2;
                    min-width: 20px;
                }
            }

            .filter-options-content {
                background-color: $cblys__lightgray;
                top: unset;
                position: relative;
            }

            .filter-options-toggle {
                padding: 15px;
                justify-content: flex-start;
            }
        }

        &-current-subtitle {
            display: none;
        }
    }

    /*.filter .filter-current {
        &.active {
            padding-bottom: 30px;

            .block-subtitle {
                @include lib-icon-font-symbol(
                        $_icon-font-content: $icon-up,
                        $_icon-font-position: before
                );
            }

            .items {
                display: block;
            }

            & + .block-actions {
                display: block;
            }
        }

        & + .block-actions {
            display: none;
        }
    }*/

    .filter-no-options {
        .filter-title {
            &:before {
                background: rgba(255, 255, 255, .5);
                content: '';
                display: block;
                height: 40px;
                left: -15px;
                margin-top: -60px;
                position: relative;
                width: 100px;
                z-index: 99;
            }
        }

        .filter-content {
            margin-bottom: $indent__base;
        }
    }

    .page-with-filter {
        .columns {
            .sidebar-main {
                order: 0;
                max-width: 100%;
            }
        }
    }
}

/**
*
* Desktop
*
*/

@include min-screen($screen__m) {
    .filter {
        &-title {
            display: none;
        }
    }
}
