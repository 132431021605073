//
//  Variables
//  _____________________________________________

$block-auth__dropdown__padding         : $indent__m !default;
$block-auth__dropdown__background-color: $color-white !default;
$block-auth__or-label__size            : 36px !default;
$block-auth__width                     : 0 !default;
$block-auth__border                    : 1px solid $color-gray-light3 !default;

//
//  Common
//  _____________________________________________

.authentication-dropdown {
    box-sizing: border-box;

    .modal-inner-wrap {
        padding: $block-auth__dropdown__padding;
    }
}
.authentication-wrapper {
    position: relative;
    z-index: 1;
    ._has-auth-shown & {
        z-index: $modal__z-index;
    }
}

.action-auth-toggle {
    @include cblys-re-outline-brown-btn;
    margin: 1rem auto;
}

.authentication-registration-message {
    display: block;
    max-width: 262px;
    margin: 1rem auto;
    text-align: center;
}


.block-authentication {
    .block-title {
        font-size: $h3__font-size;
        border-bottom: 0;
        margin-bottom: $indent__m;

        strong {
            font-weight: $font-weight__light;
        }
    }

    .field {
        .label {
            font-weight: $font-weight__regular;
        }
    }

    .actions-toolbar {
        margin-bottom: $indent__xs;

        & > .secondary {
            padding-top: $indent__m;
            text-align: left;
        }
    }

    .action.action-register,
    .action.action-login {
        @extend .abs-button-l;
    }

    .block[class] {
        margin: 0;

        ul {
            list-style: none;
            padding-left: $indent__s;
        }

        .field {
            .control,
            .label {
                float: none;
                width: auto;
            }
        }

        & + .block {
            border-top: 1px solid $color-gray-light5;
            margin-top: $indent__xl;
            padding-top: $indent__xl;
            position: relative;

            &::before {
                height: $block-auth__or-label__size;
                line-height: $block-auth__or-label__size - 2px;
                margin: (-$block-auth__or-label__size / 2 + 1px) 0 0 (-$block-auth__or-label__size / 2);
                min-width: $block-auth__or-label__size;
                background: $color-white;
                border: 1px solid $color-gray-light5;
                border-radius: 50%;
                box-sizing: border-box;
                color: $color-gray-light5;
                content: attr(data-label);
                display: inline-block;
                left: 50%;
                letter-spacing: normal;
                padding: 0 0.2rem;
                position: absolute;
                text-align: center;
                text-transform: uppercase;
                top: 0;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .authentication-dropdown {
        position: absolute;
        top: 100%;
        width: 100%;
        background-color: $block-auth__dropdown__background-color;
        border: $block-auth__border;
        text-align: left;
        transform: scale(1, 0);
        transform-origin: 0 0;
        transition: transform linear 0.15s, visibility 0 linear 0.15s;
        visibility: hidden;

        &._show {
            z-index: $dropdown-list__z-index;
            transform: scale(1, 1);
            transition: transform linear 0.15s, visibility 0 linear 0;
            visibility: visible;
        }
    }

    .authentication-wrapper {
        display: flex;
        align-items: center;
    }

    .action-auth-toggle {
        max-width: 300px;
        margin-left: 0;
    }

    .authentication-registration-message {
        text-align: left;
        margin-left: auto;
        margin-right: 0;
    }

    .block-authentication {
        .block-title {
            font-size: headings($heading-h2, font-size);
            border-bottom: 0;
            margin-bottom: $indent__m;
        }

        .actions-toolbar {
            & > .primary {
                float: right;
                margin-right: 0;
                .action {
                    margin-right: 0;
                }
            }

            & > .secondary {
                float: left;
                margin-right: 2rem;
                padding-top: 1rem;
            }
        }
    }

    .popup-authentication {
        .modal-inner-wrap {
            min-width: $screen__m;
            width: 60%;
        }

        .block-authentication {
            display: flex;
            flex-direction: row;
            border-top: 1px solid $color-gray-light5;
        }

        .block[class],
        .form-login,
        .fieldset,
        .block-content {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
        }

        .block[class] {
            box-sizing: border-box;
            float: left;
            padding: $indent__s $indent__l 0 0;
            width: 50%;

            & + .block {
                border-left: 1px solid $color-gray-light5;
                border-top: 0;
                margin: 0;
                padding: $indent__s 0 0 $indent__xl;

                &::before {
                    left: 0;
                    top: 50%;
                }
            }
        }

        .actions-toolbar {
            margin-bottom: 0;
            margin-top: auto;
        }
    }
}
