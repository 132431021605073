//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    header .header {
        &.content {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            padding: .5rem;
            min-height: 50px;

            & > .nav-toggle {
                position: static;
                padding: 0;
                margin: 0;
            }

            & > .logo {
                margin: auto;
            }

            & > .block.block-search {
                .control {
                    border-width: 0;
                }

                .field.search {
                    position: relative;

                    .label {
                        top: 0;
                    }
                }
            }
        }
    }

    header .nav-toggle:before {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        line-height: 24px;
        background: $polarcore-nav-toggle-special__background;
        box-shadow: 0 0 0 4px $polarcore-nav-toggle-special__background;
    }
}
