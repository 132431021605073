/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    &.slick-initialized {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
    }
    &.product-items {
        .product-item {
            margin-bottom: 0;
            border-bottom: none;
        }
    }
}

.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: flex;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}

.slick-arrow {
    position: relative;
    z-index: 1;
    font-size: 2rem;
    padding: 0.5rem;
    cursor: pointer;
    &.slick-prev {
        margin-right: -2rem;
        margin-left: -0.5rem;
    }
    &.slick-next {
        margin-left: -2rem;
        margin-right: -0.5rem;
    }
    &:hover {
        background: #f0f0f0;
    }
}

.slick-slide {
    float: left;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}

.slick-arrow.slick-hidden {
    display: none;
}


