.smile-es-range-slider {
    display: flex;
    flex-wrap: wrap;

    .range-slider-input-wrapper {
        margin-bottom: 1rem;
        margin-top: 0.5rem;
        padding: 0 0.1rem;
        position: relative;
        width: 50%;

        &:first-of-type {
            padding-left: 0;
        }

        &:last-of-type {
            padding-right: 0;
        }

        input {
            background: $cblys__lightgray;
            height: auto;
            margin: 0;
            padding: 0.5rem;
        }
    }

    .actions-toolbar {
        width: 100%;
        padding: 0;
        margin: 0;

        .actions-primary {
            .action {
                display: flex;
                justify-content: center;
                border: 1px solid $cblys__dark-blue;
                margin: 15px 0 5px 0;
                font-size: 18px;
                padding: 5px;
                cursor: pointer;
            }
        }
    }

    &[data-role="range-price-slider-price"] {
        .range-slider-input-wrapper {
            input {
                padding-right: 1.5rem;
            }

            &:after {
                content: 'kr';
                color: $cblys__darkgray;
                position: absolute;
                padding: 0.5rem 0;
                right: 0.5rem;
            }
        }
    }
}


@include min-screen($screen__m) {
    .smile-es-range-slider {
        .range-slider-input-wrapper {
            input {
                border: none;
            }
        }

        &[data-role="range-price-slider-price"] {
            .range-slider-input-wrapper {
                input {
                    padding-right: 1.3rem;
                }

                &:after {
                    right: 0.25rem;
                }
            }
        }
    }
}
