/*
*
* Common Product View Extensions
*
*/

.product-info-main {
    .product-reviews-summary {
        padding: 1rem 0;
    }

    .bundle-actions {
        margin-top: 1.5rem;
    }

}

.product-discount-message {
    color: black;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.37;
    text-align: right;
}

@include max-screen($screen__m) {
    .product-discount-message {
        text-align: center;
    }
}
