.page-header .panel.wrapper {
    background-color: $cblys__white;
}

.cblys-top-links__item span::before {
    color: $cblys__pear;
}

.header.panel {
    display: none;
    padding-bottom: 12px;
    background-color: $cblys__white;

    .cblys-toplinks {
        flex-grow: 1;

        .block.block-static-block {
            margin: 0;
            flex-grow: 1;
        }
    }

    .cblys-top-links {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        &__item {
            display: flex;
            align-items: center;
            list-style: none;
            margin: 0;
            color: $polarcore-top-links__color;
            font-size: 14px;
            line-height: 20px;

            & + .cblys-top-links__item,
            ~ a {
                padding-left: 3rem;
            }
            & ~ a {
                display: none;
            }

            .cblys-top-links__anchor:hover {
                border: none;
                color: $cblys__black-shade;

                &::before {
                    color: $cblys__pear-shade;
                }
            }

            .icon-map,
            .icon-heart,
            .icon-question {
                &::before {
                    color: $cblys__pear;
                }
            }

            &.first {
                flex-grow: 1;
            }
        }

        &__anchor {
            display: flex;
            align-items: baseline;
            font-weight: 500;
            border-top: 3px solid transparent;
            border-bottom: 3px solid transparent;

            &:hover {
                border-bottom: 3px solid $cblys__black;
                text-decoration: none;
            }
        }
    }
    .cblys-usp .cblys-top-links {
        justify-content: flex-start;
    }
}

@include min-screen($screen__l) {
    header.page-header {
        .header {
            &.panel {
                padding: $layout-indent__width / 2 $layout-indent__width;
                display: block;
            }
        }
    }
}

@include min-screen($screen__xl) {
    header.page-header {
        .header {
            &.panel {
                .cblys-top-links__item ~ a {
                    display: initial;
                }
            }
        }
    }
}
