

.footer-logo {
    .nav-toggle {
        display: none !important;
    }

    .logo {
        display: block;
        margin: 2rem 0;
    }
}

