@mixin slick-arrows {
    display: block;
    height: 32px;
    width: 32px;
    border: 1px solid $cblys__black;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-family: $font-family__base;
}

@mixin slick-arrows-pointers {
    content: '';
    display: block;
    height: 1px;
    width: 9px;
    background: $cblys__black;
    position: absolute;
}

#html-body {
    .product-related-and-upsell-wrapper .upsell,
    .product-related-and-upsell-wrapper .related {
        margin: 1rem 0;
        width: 100%;

        .block-title {
            font-size: (24rem/14);
            line-height: 32px;
            text-align: center;
            position: relative;
        }
    }

    .product-related-and-upsell-wrapper .related {
        .slick-prev {
            left: 0;
            top: 0;
        }

        .slick-next {
            right: 0;
            top: 0;
        }
    }
}

.product-items-upsell {
    list-style: none;
    margin: 0;
    max-height: 370px;
    padding: 0;
    overflow: hidden;

    &.expand {
        max-height: unset;
    }

    .product-item {
        display: grid;
        grid-gap: 16px;
        grid-template-columns: 80px 1fr 45px;
        padding: 0;
        margin: 8px 0;
        min-height: 109px;

        &:hover {
            background-color: $cblys__white;
        }

        &-photo {
            display: flex;
            align-items: center;
        }

        &-content {
            align-items: start;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 12px 0;
        }

        &-name {
            font-size: 1rem;
        }

        .price {
            font-size: (12rem/14);
        }

        &-anchor {
            display: flex;
            flex-direction: column;
            justify-content: center;

            &:hover {
                text-decoration: none;
            }
        }

        &-actions {
            display: flex;
            align-self: center;
            margin: 0;
        }
    }
}

.crosssell-td {
    padding: 0;
    display: block;
}

.product-items-crosssell {
    list-style: none;
    margin: 0;
    padding: 0;
    max-height: 455px;
    overflow: hidden;

    .product-item {
        grid-template-areas:
            'image content'
            'actions actions';
        grid-template-columns: 64px 1fr;
        background-color: $cblys__lightgray3;
        display: grid;
        grid-gap: 12px;
        padding: 12px;
        margin: 8px 0;
        min-height: 109px;

        &:first-of-type {
            margin-top: 0;
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        &-picture {
            grid-area: image;
            display: flex;
            align-items: center;
        }

        &-content {
            grid-area: content;
        }

        &-title {
            font-family: $font-family__base;
            font-weight: bold;
            font-size: 1rem;
            line-height: (19.12rem/14);
            margin: 0 0 8px 0;
        }

        &-name {
            font-size: 1rem;
            line-height: (19.12rem/14);
            margin: 0 0 2px 0;
        }

        .price {
            font-size: (12rem/14);
            font-weight: bold;
            line-height: (16rem/14);

            .suggested-qty, .price-box {
                display: inline-block;
            }

            .price-box span.old-price {
                margin-right: 8px;
                text-decoration: none;

                .price {
                    color: $cblys__black;
                    font-size: (12rem/14);
                    font-weight: bold;
                }
            }

            .custom-price-box.with-advertised-price .old-price {
                display: none;
            }
        }

        &-anchor {
            display: flex;
            flex-direction: column;
            justify-content: center;

            &:hover {
                text-decoration: none;
            }
        }

        &-actions {
            grid-area: actions;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0;

            button {
                flex-grow: 0;
                padding: 8px 20;
                border: none;
                min-width: 110px;

                &:hover, &:focus {
                    outline: 1px solid $cblys__darkbrown;
                    box-shadow: none;
                }
            }

            .cross-sell-checkbox-label {
                cursor: pointer;

                &:hover, &:focus {
                    outline: 1px solid $cblys__darkbrown;
                }

                button {
                    pointer-events: none;

                    span {
                        padding-right: 8px;
                        user-select: none;
                    }

                    input {
                        margin: 0 0 3px 0;
                    }
                }
            }
        }

        &-labels {
            display: flex;
            gap: 4px;
            align-items: center;
            flex-direction: row-reverse;

            a {
                display: block;
                font-size: (12rem/14);
                line-height: 12px;
                text-decoration: underline;
            }
        }

        .energy-label {
            margin-top: 0;
        }
    }
}

.upsell {
    .show-all {
        display: flex;
        justify-content: center;
    }

    .show-all-btn {
        height: 36px;
        margin: 16px 0;
        padding: 0 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        line-height: 14px;

        &:hover {
            background-color: $cblys__beige;
            text-decoration: none;
        }

        &:active,
        &:focus {
            background-color: $cblys__beige-active;
        }

        .show-less {
            display: none;
        }

        &.expand {
            .show-less {
                display: inline;
            }

            .show-more {
                display: none;
            }

            svg {
                transform: rotate(180deg);
            }
        }
    }

    .energy-label {
        margin-top: initial;
    }
}

@include min-screen($screen__m) {
    .product-items-crosssell {
        .product-item {
            grid-template-areas: 'image content actions';

            &-actions {
                flex-direction: column;
            }

            &-labels {
                flex-direction: row;
            }

            .energy-label {
                a {
                    order: -1;
                }
            }
        }
    }

    .product-items-upsell {
        max-height: 455px;

        .product-item {
            grid-template-columns: 105px 1fr 45px;
        }
    }

    .upsell {
        .product-item-content {
            justify-content: center;
            padding: 0;
        }

        .energy-label {
            margin-top: 24px;
        }
    }
}
