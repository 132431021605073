.category-hero {
    background-color: $cblys__re-light-brown;
    display: flex;
    align-items: center;
    min-height: 120px;
    margin: 15px 0 30px 0;

    @include max-screen($screen__l) {
        flex-direction: column;
    }

    .page-title-wrapper {
        flex-grow: 1;
        width: 100%;
        max-width: 400px;
        color: $cblys__white;
    }

    .page-main & h1 {
        margin: 10px;
        text-align: center;
        font-size: 38px;
        line-height: 1;

        @include max-screen($screen__l) {
            margin: 35px 10px 0 10px;
        }
    }

    .category-description {
        color: $cblys__white;
        font-size: 18px;
        padding: 35px 20px;
    }
}
