@mixin cblys-base-image() {
    border-radius: $cblys__border-radius;
}

@mixin cblys-product-image($height: 100%) {
    @include cblys-base-image();
    height: $height;
    width: $height * 0.75;
    margin: 10px 0;
    img {
        height: 100%;
        object-fit: cover;
    }
}

img {
    @include cblys-base-image();
}

.minicart-wrapper .product:not(.product-item) .product-item-photo .product-image-wrapper {
    @include cblys-product-image(109px);
    margin: 0;
}
