body.filter-active {
    .filter-title {
        display: block !important;
    }
}

.sidebar-toolbar {
    display: flex;
    margin: 15px 0 5px 0;
    min-height: 40px;
    width: 100%;
    justify-content: flex-end;

    &__sorting {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: $cblys__dark-blue;
        min-width: 200px;

        > span {
            padding: 0 10px 0 0;
        }

        .fancy-select {
            width: 100%;
        }

        .ss-main .ss-single-selected {
            background-color: transparent;
            padding: 10px;

            &.ss-open-below {
                background-color: $cblys__lightgray;
                border: 1px solid $cblys__almostdarkgray;
                border-bottom: none;
                border-radius: 0;
            }
        }

        .ss-main .ss-content {
            background-color: $cblys__lightgray;
            border-radius: 0;
            border: 1px solid $cblys__almostdarkgray;
            border-top: none;
            box-shadow: none;

            .ss-list .ss-option {
                border-bottom: 1px solid $cblys__almostdarkgray;

                &:last-child {
                    border: none;
                }

                &.ss-disabled {
                    background-color: $cblys__lightgray;
                }
            }
        }
    }

    .catalogsearch-result-index & {
        padding: 0 $layout-indent__width;
    }
}

//
//  Mobile
//  _____________________________________________
@include max-screen($screen__m) {
    .sidebar-toolbar {
        margin-right: 1rem;
        width: auto;
    }

    .column.main {
        margin: 1rem;
    }
}

//
//  Desktop
//  _____________________________________________
@include min-screen($screen__m) {
    .sidebar-toolbar {
        .catalogsearch-result-index & {
            padding: 0;
        }
    }
}
