.cblys-success-page {
    /* Unwanted section in footer comes from cms block */
    .cblysfooter .footer-about-us {
        display: none;
    }

    .checkout-success {
        display: grid;
        grid-template-areas:
            "title"
            "infoblocks"
            "order-details"
            "sidebar"
            "cmsblock";
        grid-template-columns: 1fr;
        margin: 56px 0 0 0;
        column-gap: 72px;
        row-gap: 40px;

        @include min-screen($screen__l) {
            grid-template-areas:
                "title sidebar"
                "infoblocks sidebar"
                "order-details sidebar"
                "cmsblock cmsblock";
            grid-template-columns: 2fr 1fr;
        }


        .title {
            grid-area: title;

            h1.heading {
                margin: 0;
                font-size: 36px;
                line-height: 44px;
            }

            .funny-line {
                font-size: 20px;
                line-height: 32px;
            }
        }

        .infoblocks {
            grid-area: infoblocks;
            display: flex;
            flex-direction: column;
            row-gap: 16px;

            .shipping-info,
            .account-info,
            #registration {
                background-color: $cblys__white;
                display: flex;
                flex-direction: column;
                padding: 16px 24px;
                align-items: center;
                gap: 24px;
                align-self: stretch;

                @include min-screen($screen__m) {
                    flex-direction: row;
                }

                img {
                    flex-shrink: 0;
                }
            }

            #registration {
                margin: 0;

                .registration-content {
                    align-items: center;
                    display: flex;
                    flex-direction: column;

                    a {
                        background-color: $cblys__pear;
                        border: none;
                        flex-shrink: 0;
                        font-size: 1rem;
                        margin: 24px 0 0 0;
                        padding: 12px 24px;
                        text-align: center;
                        width: 100%;
                    }

                    @include min-screen($screen__m) {
                        flex-direction: row;

                        a {
                            margin: 0 0 0 24px;
                            width: initial;
                        }
                    }
                }
            }

            span, p {
                flex-grow: 1;
                font-size: 16px;
                letter-spacing: 0.5px;
                margin: 0;
            }

            a.button {
                @include cblys-re-green-btn;
                flex-grow: 0;
                white-space: nowrap;
            }
        }


        .order-details {
            grid-area: order-details;

            .order-mini-title {
                font-size: 24px;
                line-height: 32px;
                display: flex;
                white-space: nowrap;
                margin-top: 16px;

                &:after {
                    content: '';
                    border-bottom: 1px solid $cblys__lightgray3;
                    width: 100%;
                    height: 14px;
                    margin-left: 16px;
                }
            }

            .product-name {
                font-size: 16px;
                line-height: 28px;
                letter-spacing: 0.5px;
                display: block;
            }

            .product-stock {
                font-size: 13px;
                font-weight: 300;
                letter-spacing: 0.13px;

                .stock {
                    display: flex;
                    align-items: center;
                    gap: 6px;
                }
            }

            @include max-screen($screen__m) {
                /* See _cart.scss for shared styles */
                .order.table-wrapper {
                    .items {
                        border-top: none;
                    }

                    .order.item:last-of-type {
                        border-bottom: $border-width__base solid $cblys__lightgray3;
                    }
                }
            }

            .order-summary {
                tr {
                    th, td {
                        padding: 4px 0 4px 0;
                    }
                }

                th,
                td > .price,
                :not(.old-price) > .price, {
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0.25px;
                }

                td.amount {
                    display: flex;
                    flex-direction: column;
                    justify-content: end;
                    text-align: right;
                }

                tr.grand_total{
                    th, td {
                        padding-top: 20px;
                    }

                    th, .amount :not(.old-price) > .price {
                        font-size: (20rem/14);
                        font-weight: 700;
                        line-height: 1.6;
                        vertical-align: middle;
                    }
                }

                @include max-screen($screen__m) {
                    margin-top: 20px;
                }
            }
        }

        #klarna_checkout_success {
            grid-area: sidebar;

            iframe {
                width: 100%;
                aspect-ratio: 1;
            }
        }

        .cmsblock {
            grid-area: cmsblock;
        }
    }
}
