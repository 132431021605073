//  Layout
$layout-column__sidebar-width: 3;
$_breadcrumbs-container-margin: 0;

// General
$font-family__base: 'AvenirNextLTPro', Helvetica, sans-serif;
$font-family__header: 'Frank Ruhl Libre', serif;

//  Navigation
$navigation__level0-item-line-height: 47px;
$navigation__level0-item-font-size: 15px;

$product-name-link__color: #2C313E !default;
$product-name-link__color__active: #2C313E !default;
$product-name-link__color__hover: #2C313E !default;
$product-name-link__color__visited: #2C313E !default;

$_button-color: #606675;
$border-color__base: #E0E6EE;
$button__background: #e0e6ee;
$button__border: 0;
$button__border-radius: 0;
// $button__hover__color: $button__color;
$button__hover__background: darken($button__background, 5%);
$button__active__background: darken($button__background, 5%);

$rating-icon__color: $cblys__gray;
$rating-icon__active__color: #FFCD4D;
$rating-icon__font-size: 24px;
$review-ratings-left: 200px;

$size-fotorama-block: 40px;
$fotorama-arw-size: 40px;

/**
*
* Layout
*
*/

$layout-indent__width: 10px;
$layout__max-width: 1280px;

/**
*
* PolarCore Tabs mode
*
*/

$polarcore-tabs__mode: 'default';

// Icon overrides

$icon-font: 'Cblys Icons', 'Material Icons';

$icon-wishlist-full: '\e600' !default;
$icon-wishlist-empty: '\e601' !default;
$icon-warning: '\e602' !default;
$icon-success: '\e876' !default;
$icon-update: '\e603' !default;
$icon-trash: '\e604' !default;
$icon-settings: '\e606' !default;
$icon-pointer-down: '\e607' !default;
$icon-next: '\e608' !default;
$icon-menu: '\e609' !default;
$icon-location: '\e60a' !default;
$icon-list: '\e60b' !default;
$icon-info: '\e60c' !default;
$icon-grid: '\e60d' !default;
$icon-comment-reflected: '\e60e' !default;
$icon-collapse: '\e60f' !default;
$icon-calendar: '\e612' !default;
$icon-prev: '\e617' !default;
$icon-pointer-up: '\e618' !default;
$icon-pointer-right: '\e619' !default;
$icon-pointer-left: '\e61a' !default;
$icon-flag: '\e61b' !default;
$icon-expand: '\e61c' !default;
$icon-envelope: '\e61d' !default;
$icon-compare-full: '\e61e' !default;
$icon-compare-empty: '\e61f' !default;
$icon-comment: '\e620' !default;
$icon-up: '\e621' !default;
$icon-down: '\e622' !default;
$icon-help: '\e623' !default;
$icon-arrow-right-thin: '\e624' !default;
$icon-arrow-left-thin: '\e625' !default;
$icon-arrow-down-thin: '\e626' !default;
$icon-account: '\e627' !default;
$icon-gift-registry: '\e628' !default;
$icon-present: '\e629' !default;
$icon-arrow-up-thin: '\e633' !default;
$icon-cac: '\e924'!default;

$icon-cart: '\e907';
$icon-addtocart: '\e901';
$icon-star: '\e91f';
$icon-location: '\e910';
$icon-checkmark: '\e908';
$icon-arrow-up: '\e904';
$icon-arrow-down: '\e902';
$icon-search: '\e919';
$icon-remove: '\e909';
$icon-clear: '\e14c';
$icon-menu: '\e911';
$icon-arrow-left-thin: '\e916';
$icon-tune: '\e429';

// Responsive
$screen__xxs: 380px !default;
