body.sticky {
    &--clear {
        .page-header {
            top: 0;
            opacity: 0;
            transform: translateY(-200%);
            box-shadow: none;
            position: fixed;
            z-index: 105;
            width: 100%;
        }

        .panel.header {
            display: none;
        }

        .header.content {
            flex-wrap: nowrap;
        }

        .meganav {
            &__list-root {
                height: 100vh;
            }

            &--open {
                .meganav__backdrop {
                    height: 100vh;
                    width: 101vw;
                    margin-top: 55px;
                }
            }
        }
    }

    &--up {
        .page-header {
            transform: translateY(-100%);
            transition: transform 0.6s ease, top 0.6s ease, opacity 0.8s ease;
        }
    }

    &--down {
        .page-header {
            transform: translateY(0%);
            opacity: 1;
            transition: transform 0.6s ease, top 0.6s ease, opacity 0.8s ease;

            .meganav--desktop {
                max-width: unset;
            }

            .header.content {
                .cblys-top-links__anchor,
                .minicart-wrapper,
                #cac-cart-trigger {
                    width: 60px;
                }

                .block.block-search {
                    .block-title strong {
                        display: none;
                    }

                    .action.search {
                        right: 40px;
                    }

                    .block.block-content {
                        overflow: hidden;
                        width: 0px;
                        right: -230px;
                        left: unset;
                        position: absolute;
                        transition: width 0.5s ease;
                        top: 80px;
                        padding-bottom: 8px;
                        padding-right: 34px;

                        &.active {
                            width: 99vw;
                            max-width: 1280px;
                            z-index: 51;
                            right: -230px;
                            top: 80px;
                            padding: 0 34px 8px 24px;
                            background-color: $cblys__lightgray2;

                            #search {
                                width: 100%;
                            }
                        }
                    }
                }
            }

            .cblys-top-links__anchor.icon-user span {
                display: none;
            }

            .block.block-title {
                margin-top: 5px;
                margin-left: 34px;
                height: 64px;
                width: 60px;
            }

            .block.block-content.active
                #search_mini_form
                .field.search
                .control {
                #search_autocomplete {
                    box-shadow: none;
                    background-color: $cblys__white;
                    margin-top: 0;
                    left: 24px;
                    max-width: 1222px;
                }
            }
        }
    }
}

@include min-screen($screen__l) {
    body.sticky {
        &--clear {
            header.page-header .header.panel {
                display: none;
            }

            .sticky-logo {
                display: block;
                margin-right: 88px;
            }

            .main-logo {
                display: none;
            }

            .header.content {
                padding: 20px 32px;

                .logo {
                    order: -1;
                    margin: 0;
                    position: relative;
                    transform: none;
                    left: auto;
                    top: 0;
                }

                .block.block-search {
                    margin: 0 1rem 0 auto;
                    width: auto;
                    flex-direction: row-reverse;

                    .block-title {
                        display: block;
                    }
                }

                .minicart-wrapper,
                #cac-cart-trigger {
                    width: auto;
                    padding-bottom: 0;

                    .text,
                    .cac-trigger-label {
                        display: none;
                    }
                }

                .clickandcollect {
                    margin: 0 1rem;
                }
            }

            .meganav {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                height: 100%;
                max-width: 835px;

                &__list-root {
                    height: auto;
                }

                &__item--open .meganav__list--open.level1 {
                    top: 100px;
                    width: 1280px;
                    left: 7px;
                }

                &__backdrop {
                    top: 45px;
                    left: calc((100% - 100vw) / 2);
                }
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    body.sticky {
        &--down {
            header.page-header .header.content {
                .block.block-search {
                    .block.block-title {
                        margin: 7px 0 0 0px;
                    }

                    .block.block-content {
                        overflow: unset;
                        &.active {
                            width: 95.6vw;
                            top: 70px;
                            padding: 0;
                            right: 0;
                            left: 9px;
                        }
                    }

                    .action.search {
                        right: 5px;
                        top: -7px;
                    }
                    .block.block-content
                        #search_mini_form
                        .field.search
                        .control {
                        padding-bottom: 5px;
                        #search_autocomplete {
                            width: 100% !important;
                            margin-top: 0;
                            left: -8px;
                        }
                    }
                }
                #cac-cart-trigger,
                .minicart-wrapper {
                    width: 40px;
                }
            }
        }
    }
}

//
//  Tablet
//  _____________________________________________

@include screen($screen__m, $screen__l) {
    body.sticky {
        &--down {
            header.page-header .header.content {
                .nav-toggle {
                    top: 12px;

                    span {
                        display: none;
                    }
                }

                .block.block-search {
                    .block.block-content {
                        max-width: 1023px;
                        padding: 10px;
                        overflow: unset;

                        &.active {
                            right: 0;
                        }
                    }

                    .block.block-title {
                        margin: 6px 0 0 0;

                        strong {
                            display: none;
                        }
                    }
                }

                .block.block-search {
                    .block.block-title {
                        margin: 7px 0 0 10px;
                    }

                    .block.block-content {
                        &.active {
                            width: 96.6vw;
                            top: 86px;
                            padding: 0;
                            right: 0;
                            left: 14px;
                        }
                    }
                    .action.search {
                        right: 5px;
                        top: -7px;
                    }
                    .block.block-content
                        #search_mini_form
                        .field.search
                        .control {
                        padding-bottom: 8px;
                        #search_autocomplete {
                            width: 100% !important;
                            margin-top: 0px;
                            left: -15px;
                        }
                    }
                }

                .cblys-header-item__wrapper .icon-user::before {
                    margin-top: 0;

                    span {
                        display: none;
                    }
                }

                .cac-trigger-label span {
                    display: none;
                }

                .minicart-wrapper .action.showcart .text {
                    display: none;
                }
            }
        }
    }
}
