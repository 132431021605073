//
//  Sections variables
//  _____________________________________________

//
//  Tabs
//  ---------------------------------------------

//  Tabs controls

$tab-control__font-family: inherit !default;
$tab-control__font-style: inherit !default;
$tab-control__height: $indent__base !default;

$tab-control__padding-top: $indent__xs !default;
$tab-control__padding-bottom: $indent__xs !default;
$tab-control__padding-right: $indent__base !default;
$tab-control__padding-left: $tab-control__padding-right !default;
$tab-control__border-color: $border-color__base !default;
$tab-control__border-width: 0 !default;
$tab-control__background-color: #ffffff;
$tab-control__active__background-color: #ffffff;

//  Current tab
$tab-control__background-color: $panel__background-color !default;
$tab-control__color: $text__color__muted !default;
$tab-control__hover__color: $tab-control__color !default;
$tab-control__hover__background-color: lighten($tab-control__background-color, 5%) !default;
$tab-control__text-decoration: none !default;
$tab-control__hover__text-decoration: $tab-control__text-decoration !default;
$tab-control__active__background-color: lighten($tab-control__background-color, 15%) !default;
$tab-control-font-weight: 400;

$tab-content__border: $tab-control__border-width solid $tab-control__border-color !default;
$tab-content__margin-top: 0;

$tab-content__padding-top: $indent__base !default;
$tab-content__padding-right: $indent__base !default;
$tab-content__padding-bottom: $indent__base !default;
$tab-content__padding-left: $indent__base !default;

$accordion-control__padding-top: $tab-control__padding-top !default;
$accordion-control__padding-right: $indent__base !default;
$accordion-control__padding-bottom: $tab-control__padding-bottom !default;
$accordion-control__padding-left: $indent__base !default;
