.modal-popup {
    pointer-events: none;

    .modal-inner-wrap {
        max-width: 450px;
    }
}

.modal-popup.confirm {
    .modal-footer {
        button:not(.action-dismiss) {
            @include cblys-re-green-btn;
            width: 100%;
            margin: 1rem 0;
        }

        button.action-dismiss {
            @include cblys-transparent-button;
            width: 100%;
            margin: 1rem 0;
        }
    }
}

.energy-label-popup {
    .modal-inner-wrap {
        border-radius: 8px;
        width: 272px;
    }

    .modal-header {
        padding-top: 30px;
        padding-bottom: 10px;

        .action-close:before {
            font-size: 24px;
            line-height: 24px;
        }
    }

    .modal-content {
        padding: 0 36px 36px 36px;

        > div {
            align-items: center;
            display: flex;
            justify-content: center;
            min-height: 100px;
        }
    }
}
