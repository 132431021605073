// Colors
$cblys__black: #1c1c1b;
$cblys__black-shade: #000;
$cblys__white: #fff;
$cblys__lightgray: #fafafa;
$cblys__gray: #ececec;
$cblys__slightgray: #e2e2e2;
$cblys__almostdarkgray: #bababa;
$cblys__darkgray: #828282;
$cblys__darkergray: #2e3131;
$cblys__blue: #00bcd4;
$cblys__light-blue: #4dd0e1;
$cblys__lighter-blue: #e4f1fe;
$cblys__dark-blue: #042c3b;
$cblys__green: #3fc380;
$cblys__red: #cf000f;
$cblys__yellow: #ffcd4d;
$cblys-hyperlink__blue: #039fd5;
$cblys__lightgray2: #f3f1ef;
$cblys__lightgray3: #DBD9D7;
$cblys__pine: #515c45;
$cblys__pine-light: #747d6a;
$cblys__meganav-open: #5c554e;
$cblys__beige: #dcccbc;
$cblys__beige-active: #C6B8A9;
$cblys__shadow: rgba(92, 85, 78, 0.5);
$cblys__pear: #b0b487;
$cblys__pear-shade: #8d906c;
$cblys__darkbrown: #5c554e;
$cblys__darkbrown-shade: #4a443e;
$cblys__beige-main: #e3d6c9;
$cblys__dark-beige: #c6bba9;

// CBLYS-449 - Update visual profile
// Added re- prefix to scope to new redesigned styles and avoid conflicts
$cblys__re-green: #8d906c;
$cblys__re-light-green: #b0b487;
$cblys__re-lighter-green: #c0c39f;

$cblys__re-brown: #4a443e;
$cblys__re-light-brown: #5c554e;
$cblys__re-lighter-brown: #7d7771;

$cblys__re-beige: #d1c7bb;

$cblys__re-peach: #c5876e;
// End of redesign styles

$primary__color: $cblys__black;
$secondary__color: $cblys__lightgray;

$page-main__background-color: $cblys__lightgray2;

$cblys__message-color--info: #C2D0E7;
$cblys__message-icon-color--info: #2B4268;
$cblys__message-color--error: #E4B29C;
$cblys__message-icon-color--error: #883510;
$cblys__message-color--success: #D7D9C3;
$cblys__message-icon-color--success: $cblys__pine;
$cblys__message-color--warning: #F1E8B3;
$cblys__message-icon-color--warning: #997F2D;

$button-close__gray: $cblys__darkgray;
$active__color: $cblys__blue;

// Buttons
$cblys-button__text-color: $cblys__black;
$cblys-button__border-color: $cblys__re-lighter-brown;
$primary-button__background-color: $cblys__pear;
$primary-button__background-color__hover: $cblys__re-lighter-green;
$primary-button__background-color__focus: $cblys__pear-shade;
$secondary-button__background-color: transparent;
$secondary-button__background-color__hover: $cblys__beige-main;
$secondary-button__background-color__focus: $cblys__dark-beige;
$link-button__background-color: transparent;
$link-button__background-color__hover: $cblys__beige-main;
$link-button__background-color__focus: $cblys__dark-beige;
$tonal-button__background-color: $cblys__beige;
$tonal-button__background-color__hover: $cblys__beige-main;
$tonal-button__background-color__focus: $cblys__dark-beige;
