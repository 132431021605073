.visma-color {
    order: 10;
    margin: 2rem 0 0;

    &__label {
        @include cblys-overline();
        display: block;
        text-align: center;
    }

    &__container {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    &__link {
        position: relative;
        width: 66px;
        margin: 5px;
        border: 1px solid $cblys__black;
        transition: .3s;

        &--current-product {
            border: 3px solid $cblys__green;
        }

        &::before {
            content: attr(data-color-label);
            @include cblys-overline();
            position: absolute;
            display: block;
            width: auto;
            bottom: -1.5rem;
            left: 50%;
            padding: 0.25rem;
            text-align: center;
            white-space: nowrap;
            background-color: #ffffff;
            pointer-events: none;
            box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
            z-index: 2;
            transform: translateX(-50%);
            opacity: 0;
            transition: .3s;
            position: absolute;
        }

        &:hover {
            box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
            &::before {
                opacity: 1;
            }
        }
    }
}
