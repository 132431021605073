//
//  Variables
//  ---------------------------------------------

$polarcore__border-color: #ededed !default;
$polarcore__special-price-color: #FF4444 !default;


$product-name-link__color: #232323 !default;
$product-name-link__color__active: #232323 !default;
$product-name-link__color__hover: #232323 !default;
$product-name-link__color__visited: #232323 !default;

$product-name-link__text-decoration: none !default;
$product-name-link__text-decoration__active: $link__hover__text-decoration !default;
$product-name-link__text-decoration__hover: $link__hover__text-decoration !default;
$product-name-link__text-decoration__visited: $link__hover__text-decoration !default;

//
//  Product Lists
//  _____________________________________________

a.product-item-link {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    display: inline-block;
    overflow: hidden;
}

.inner .product-item .special-price {
    order: initial;
}

.widget {
    .product-items__showmore {
        display: none;
    }
}

.product {
    &-items {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        padding: 0;
        margin: 0 -0.5rem;
    }

    &-manufacturer-name {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 22px;
        letter-spacing: 0.04em;
        padding: 0.25rem 0;
        color: $cblys__re-light-brown;
        min-height: 26px;
        text-align: left;
    }

    .product-item-details {
        margin: 0.8rem;
    }

    &-item {
        position: relative;
        padding: 0.5rem;

        .products-grid & {
            display: flex;
            width: 100%;
        }

        &-name {
            display: block;
            word-wrap: break-word;
            hyphens: auto;
            font-style: normal;
            font-weight: 400;
            font-size: 1.15rem;
            line-height: 1.5rem;
            letter-spacing: 0.04em;
            margin-bottom: 0.15rem;

            & > a {
                @include lib-link(
                    $_link-color: $product-name-link__color,
                    $_link-text-decoration: $product-name-link__text-decoration,
                    $_link-color-visited: $product-name-link__color__visited,
                    $_link-text-decoration-visited: $product-name-link__text-decoration__visited,
                    $_link-color-hover: $product-name-link__color__hover,
                    $_link-text-decoration-hover: $product-name-link__text-decoration__hover,
                    $_link-color-active: $product-name-link__color__active,
                    $_link-text-decoration-active: $product-name-link__text-decoration__active
                );
            }
        }

        &-info {
            display: flex;
            flex-direction: column;
            max-width: 100%;
            text-align: center;
            width: 100%;
            position: relative;

            &-link {
                position: absolute;
                width: 100%;
                height: 100%;
                display: block;
                top: 0;
                left: 0;
            }

            .product-item-photo {
                position: relative;
            }

            &:hover {
                background: #fff;
                transition: 300ms;
            }
        }

        &-actions {
            display: none;
            margin: 1rem 0;
            margin-bottom: 0;
        }

        &-description {
            text-align: left;
            font-size: 12px;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 100%;
            width: 100%;
            overflow: hidden;
            padding-right: 30%;
        }

        &-details__top {
            padding-top: 0.5rem;
            text-align: left;
        }

        &-insert {
            #html-body & div[data-content-type="row"] > div {
                margin: 0;
            }
        }

        .price-box {
            .custom-price-box {
                align-items: flex-start;
                display: flex;
                flex-wrap: wrap;
                width: 100%;
            }

            .custom-price-box.with-discount {
                flex-direction: row;

                .old-price {
                    color: $cblys__darkbrown;
                    margin-right:1rem;
                    order: 1;
                    text-decoration: line-through;
                }

                .special-price {
                    color: $cblys__red;
                    order: 2;
                }
            }

            .custom-price-box.with-advertised-price {
                flex-direction: column;

                .old-price {
                    display: flex;
                    align-items: baseline;
                    order: 2;
                    color: $cblys__re-light-brown;
                    text-decoration: none;
                    margin-top: 0.3rem;

                    .price {
                        font-size: 1rem;
                        letter-spacing: 0.04em;
                    }
                }

                .special-price {
                    display: block;
                    order: 1;
                    color: $cblys__black;
                    font-size: 1.15rem; // 16px
                    letter-spacing: 0.04em;
                }
            }

            .price-label {
                display: none;
                font-size: $font-size__s;

                &:after {
                    content: ':';
                }
            }
        }

        .special-price,
        .minimal-price {
            .price-including-tax + .price-excluding-tax {
                display: block;
            }
        }

        .minimal-price {
            .price-container {
                display: block;
            }
        }

        .minimal-price-link {
            margin-top: 5px;

            .price-label {
                color: $link__color;
                font-size: 14px;
            }

            .price {
                font-weight: $font-weight__regular;
            }
        }

        .minimal-price-link,
        .price-excluding-tax,
        .price-including-tax {
            display: block;
            white-space: nowrap;
        }

        .price-from,
        .price-to {
            margin: 0;
        }

        .price-to {
            display: none;
        }

        .action.tocompare {
            @include lib-icon-font-symbol($icon-compare-empty);
        }

        .tocart {
            white-space: nowrap;
            width: 100%;
        }

        .energy-label {
            margin-top: 0;
        }
    }
}

.textoverlay {
    bottom: 0;
    color: $cblys__black-shade;
    position: absolute;
    right: 0;
    margin-left: 10px;
    display: flex;
    align-items: center;
    min-height: 30px;
    padding: 5px 10px 5px 35px;
    background: white;
    z-index: 1;
    font-size: 13px;
    text-align: left;
    line-height: 1.2;

    &:before {
        background: center url('../images/info.svg');
        background-size: cover;
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 8px;
        height: 18px;
        width: 18px;
        color: white;
        font-size: 14px;
        text-align: center;
        line-height: 18px;
        font-weight: 600;
    }
}

.mz-hint {
    top: 8px;
}

.price-container {
    .price-including-tax + .price-excluding-tax,
    .weee {
        margin-top: $indent__xs;
    }

    .price-including-tax + .price-excluding-tax,
    .weee,
    .price-including-tax + .price-excluding-tax .price,
    .weee .price,
    .weee + .price-excluding-tax:before,
    .weee + .price-excluding-tax .price {
        font-size: 11px;
    }

    .weee {
        &:before {
            content: '(' attr(data-label) ': ';
        }

        &:after {
            content: ')';
        }

        + .price-excluding-tax {
            &:before {
                content: attr(data-label) ': ';
            }
        }
    }
}

.products-list {
    .product {
        &-item {
            display: table;
            width: 100%;

            &-info {
                display: table-row;
            }

            &-photo {
                display: table-cell;
                padding: 0 $indent__l $indent__l 0;
                vertical-align: top;
                width: 1%;
            }
        }
    }
}

.amscroll-loading-block {
    max-width: 50px;
    margin: auto;
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .products-list .product {
        &-item {
            table-layout: fixed;

            &-photo {
                padding: 0 $indent__s $indent__s 0;
                width: 30%;
            }
        }
    }
}

@include min-screen($screen__s) {
    .product {
        &-item {
            .products-grid & {
                margin-bottom: 1rem;
            }

            &-actions {
                display: flex;

                .products-grid & {
                    margin: $indent__s 0;
                    margin-bottom: 0;
                }

                .actions-primary + .actions-secondary {
                    display: flex;
                    white-space: nowrap;

                    & > * {
                        white-space: normal;
                    }
                }

                .actions-primary {
                    display: flex;

                    & > form {
                        width: 100%;
                    }
                }
            }
        }
    }

    .products-grid .product-item {
        width: 33.3333%;
    }

    .page-products,
    .page-layout-1column,
    .page-layout-3columns,
    .page-products.page-layout-1column,
    .page-products.page-layout-3columns {
        .products-grid {
            .product-item {
                width: 33.3333%;
            }
        }
    }
}

@include screen($screen__xxs, $screen__m) {
    .page-products {
        .products-grid {
            .product-item {
                width: 50%;

                &.product-item-insert {
                    width: 100%;
                }
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .page-products {
        .products-grid {
            .product-item {
                width: 33.3333%;

                &.product-item-insert {
                    width: 66.6666%;
                }
            }
        }
    }

    .page-products.page-layout-1column {
        .products-grid {
            .product-item {
                width: calc(33% - 2rem);
                margin: 1rem;
            }
        }
    }

    .page-products.page-layout-3columns {
        .products-grid {
            .product-item {
                width: 50%;
            }
        }
    }
}

@include min-screen($screen__l) {
    .products-grid {
        .product-item {
            width: 20%;
        }
    }

    .page-layout-1column {
        .products-grid {
            .product-item {
                width: 16.6666%;
            }
        }
    }

    .page-layout-3columns {
        .products-grid {
            .product-item {
                width: 25%;
            }
        }
    }

    .page-products {
        .products-grid {
            .product-item {
                width: 25%;

                &.product-item-insert {
                    width: 50%;
                }
            }
        }
    }

    .page-products {
        &.page-layout-1column {
            .products-grid {
                .product-item {
                    margin-left: 0;
                    width: 20%;
                }
            }
        }

        &.page-layout-3columns {
            .products-grid {
                .product-item {
                    width: 33%;
                }
            }
        }
    }
}

/* Lipscore styles */
.lipscore-widget-rating {
    &-wrapper {
        margin-top: 0.5rem;
        margin-bottom: 1rem;
        position: relative;
    }

    &-anchor {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 1;
    }
}
