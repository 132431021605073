@mixin cblys-message(
    $cblys-message__background: $cblys__gray,
    $cblys-message__color: $cblys__black,
    $cblys-message__icon: $icon-info
) {
    display: flex;
    align-items: center;
    background: $cblys-message__background;
    padding: 16px 16px 16px 44px;
    margin-bottom: 10px;
    position: relative;
    & > *:first-child {
        @include lib-icon-font(
            $_icon-font-content: $cblys-message__icon,
            $_icon-font-size: 24px,
            $_icon-font-line-height: 24px,
            $_icon-font-color: $cblys-message__color
        );
        align-items: center;
        font-size: 16px;

        &::before {
            position: relative;
            width: 0;
            left: -2rem;
        }

        &:hover::before {
            color: $cblys-message__color;
        }
    }
    .message__close {
        color: $cblys__darkbrown;
        cursor: pointer;
        margin-left: auto;
        padding-left: 1rem;
        @include lib-icon-font(
            $icon-remove,
            $_icon-font-size: 14px,
            $_icon-font-line-height: 14px,
            $_icon-font-color: $cblys__darkbrown,
            $_icon-font-text-hide: true
        );

        &:hover::before {
            color: $cblys__darkbrown;
        }
    }
}

.message {

    &.info, &.notice {
        @include cblys-message(
            $cblys-message__background: $cblys__message-color--info,
            $cblys-message__color: $cblys__message-icon-color--info
        );
    }

    &.error {
        @include cblys-message(
            $cblys-message__background: $cblys__message-color--error,
            $cblys-message__color: $cblys__message-icon-color--error
        );
    }

    &.warning {
        @include cblys-message(
            $cblys-message__background: $cblys__message-color--warning,
            $cblys-message__icon: $icon-warning,
            $cblys-message__color: $cblys__message-icon-color--warning
        );
    }

    &.success {
        @include cblys-message(
            $cblys-message__background: $cblys__message-color--success,
            $cblys-message__icon: $icon-success,
            $cblys-message__color: $cblys__pine
        );
    }
}
