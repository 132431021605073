/* Header */
#cac-cart-trigger {
    cursor: pointer;
    min-height: 1px;
    padding: 7px 12px;

    .cac-trigger-wrapper {
        display: flex;
        align-items: flex-end;
        box-sizing: border-box;
        position: relative;

        @include lib-icon-font(
            $_icon-font-content: $icon-cac,
            $_icon-font-size: 28px,
            $_icon-font-line-height: 30px
        );
    }

    .cac-trigger-counter {
        height: 24px;
        line-height: 24px;
        display: inline-block;
        min-width: 18px;
        overflow: hidden;
        padding: 0 3px;
        text-align: center;
        white-space: normal;
        position: absolute;
        top: 6px;
        right: -10px;
        background: $cblys__re-light-brown;
        color: #fafafa;
        width: 24px;
        border-radius: 50%;
        margin: 0;
        transform: scale(0.75);
    }

    .cac-trigger-label {
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 0.5px;
        display: none;
        margin-top: -5px;
        text-transform: capitalize;
        text-align: center;

        @include min-screen($screen__m) {
            display: block;
        }

        span {
            letter-spacing: 0.5px;
        }
    }
}

/* Add To Cart */
#cac-add-to-cart {
    .cac-add-stock-status {
        @include cblys-body-text(13rem/14);
        color: $cblys__black;
        text-align: left;
        padding-top: 16px;
    }
}

#cac-product-addtocart-button {
    background: $cblys__beige;
    border: unset;
    font-family: $font-family__base;
    width: 100%;
    height: 60px;
    position: relative;

    &:before {
        position: absolute;
        top: 15px;
        left: 20px;
    }

    &:hover {
        background: $cblys__beige-main;
        box-shadow: unset;
    }
}

.cac-add-to-cart-disabled {
    align-items: center;
    background-color: $cblys__lightgray3;
    display: flex;
    font-size: (12rem/14);
    height: 60px;
    justify-content: center;
    line-height: 1.2;
    padding: 10px 24px;
}

/* Popup */
.cac-popup-wrap {
    &.modal-popup .modal-inner-wrap {
        box-sizing: border-box;
        max-height: calc(100% - 5rem);
        max-width: 500px;
        overflow-y: auto;
    }

    header.modal-header {
        padding: 0;

        .modal-title {
            background-color: $cblys__re-beige;
            border-bottom: none;
            padding: 0 16px;
            font-size: 26px;
            font-weight: 300;
            height: 65px;
            line-height: 65px;
        }

        .action-close {
            padding: 16px;
        }
    }

    div.modal-content {
        padding: 0;
    }
}

/* Checkout */
.cac-checkout {
    padding: 16px;

    .cac-hidden {
        display: none;
    }

    button.cac-button-small {
        margin: 0 auto;
    }

    .cac-selected-store-name {
        margin: 0 0 16px 0;
        font-weight: 400;
    }

    .cac-store-controls {
        margin-bottom: 16px;
    }

    .message.error {
        background-color: $cblys__re-peach;
    }
}

.cac-selected-store-title {
    font-size: 1rem;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.cac-items-empty {
    text-align: center;
    border: 1px dashed $cblys__slightgray;
    padding: 2rem;
}

.cac-item {
    border-top: 1px solid $cblys__almostdarkgray;
    display: flex;
    padding: 2rem 0;

    &:last-of-type {
        border-bottom: 1px solid $cblys__almostdarkgray;
    }

    &-image {
        flex-shrink: 0;
        width: 100px;
    }

    &-info {
        align-items: flex-start;
        display: flex;
        flex-grow: 1;
        flex-wrap: wrap;
        padding-left: 2rem;

        > * {
            padding-bottom: 1rem;
        }
    }

    &-name {
        width: 80%;
    }

    &-options {
        font-size: 1rem;
    }

    button.cac-item-remove {
        cursor: pointer;
        padding: 0;
        position: relative;
        text-align: right;
        width: 20%;

        &:before {
            font-size: 16px;
        }
    }

    &-quantity {
        align-items: center;
        justify-content: space-between;
        display: flex;
        width: 10rem;
    }

    &-qty-decrease,
    &-qty-increase {
        border: 1px solid;
        border-radius: 50%;
        font-size: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 30px;
        color: $cblys__black;
        font-weight: 300;
        height: 30px;
        width: 30px;
        cursor: pointer;

        .cac-quantity-disabled & {
            cursor: not-allowed;
        }
    }

    input.cac-item-qty-input {
        height: 37px;
        text-align: center;
        width: 57px;
        z-index: 1;
        margin: 0;
    }

    &-price {
        flex-grow: 1;
        text-align: right;
    }

    &-availability {
        width: 100%;
    }
}

.cac-form {
    margin-top: 16px;

    .cac-label {
        font-size: 1rem;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    .actions-toolbar {
        text-align: right;

        .primary {
            float: none;
            margin: 0 0 0 3px;
            width: 100%;
            display: flex;
        }

        .action.submit.secondary {
            margin: 0 3px 0 0;
            width: 100%;
            padding: 10px;
        }

        .action.submit.primary {
            margin-right: 0;
            padding: 10px;
            background-color: $cblys__re-green;
            border-color: $cblys__re-green;
        }
    }

    .cac-change-customer-wrapper {
        text-align: right;
    }

    .cac-remember-me-control {
        margin-top: 1rem;
        text-align: right;

        .cac-label {
            cursor: pointer;
            font-size: 1.25rem;
            text-transform: none;
        }
    }

    .cac-saved-form {
        margin-bottom: 2rem;
    }

    .cac-saved-field {
        margin-bottom: 1rem;

        &:nth-last-child(2) {
            margin-bottom: 0;
        }
    }

    .cac-submit-forbidden {
        margin-bottom: 0.25rem;
    }

    input.input-text.cac-input {
        border-radius: 2px;
        border-color: $cblys__black;
        padding: 2rem 1rem;
    }

    input[name="cac_email"] {
        margin-bottom: 1.5rem;
    }
}

/* Stores */
.cac-stores-search {
    background-color: $cblys__lightgray;
    padding: 16px 17px 0 16px;
}

input[type="text"].cac-stores-search-input {
    background-color: $cblys__lightgray;
    margin-bottom: 2rem;
    margin-top: 1rem;
}

.cac-no-stores {
    padding: 2rem;
    text-align: center;
}

.cac-store {
    border-top: 1px solid $cblys__almostdarkgray;
    cursor: pointer;
    padding: 2rem;

    &:hover {
        background-color: $cblys__lightgray;
    }

    &:last-of-type {
        margin-bottom: 0;
    }

    &-availability {
        color: $cblys__re-green;
        font-weight: bold;

        &.warning {
            color: $cblys__re-peach;
        }
    }

    &-name {
        font-weight: bold;
    }

    &-opening-hours {
        margin: 0;
        display: inline-flex;
        flex-direction: column;
        list-style: none;
        padding: 0 0 0 8px;
    }

    &-opening-hours__item {
        margin: 0;
        display: flex;
        justify-content: space-between;

        &[data-index="7"] {
            flex-wrap: wrap;
        }
    }

    &-opening-hours__item-day {
        padding-right: 8px;
    }
}

/* Success */
.cac-success {
    text-align: right;
    padding: 16px;

    &-message {
        padding-bottom: 2rem;
        text-align: left;
    }
}

/* Loader */
.cac-loader {
    color: $cblys__black;
    text-indent: -9999em;
    margin: 20px auto;
    position: relative;
    transform: translateZ(0);
    background: $cblys__black;
    animation: cac-load 1s infinite ease-in-out;
    animation-delay: -0.16s;
    width: 1em;
    height: 4em;

    #cac-add-to-cart & {
        margin: 0;
    }

    &-wrapper {
        font-size: 11px;
        height: 5rem;

        #cac-add-to-cart & {
            font-size: 4px;
            height: 2rem;
        }
    }

    &-container {
        padding: 16px;

        #cac-add-to-cart & {
            padding: 0 1rem;
        }
    }

    &:before,
    &:after {
        position: absolute;
        top: 0;
        content: "";
        background: $cblys__black;
        animation: cac-load 1s infinite ease-in-out;
        width: 1em;
        height: 4em;
    }

    &:before {
        left: -1.5em;
        animation-delay: -0.32s;
    }

    &:after {
        left: 1.5em;
    }
}

@-webkit-keyframes cac-load {
    0%,
    80%,
    100% {
        box-shadow: 0 0;
        height: 4em;
    }

    40% {
        box-shadow: 0 -2em;
        height: 5em;
    }
}

@keyframes cac-load {
    0%,
    80%,
    100% {
        box-shadow: 0 0;
        height: 4em;
    }

    40% {
        box-shadow: 0 -2em;
        height: 5em;
    }
}

@media only screen and (max-width: $screen__m) {
    #cac-product-addtocart-button {
        margin-bottom: 8px;
        width: 100%;
    }

    #cac-add-to-cart .cac-add-stock-status {
        text-align: center;
    }

    .cac-add-to-cart-disabled {
        background: none;
        font-size: 1rem;
        height: auto;
        padding: 0;
    }
}

@media only screen and (max-width: 500px) {
    .cac-popup-wrap .modal-inner-wrap {
        margin-top: 1rem;
        max-height: calc(100% - 2rem);
        width: 90%;
    }
}

@media only screen and (max-width: 320px) {
    .cac-popup-wrap .modal-inner-wrap {
        margin-top: 0;
        max-height: 100%;
        height: 100%;
        width: 100%;
    }
}
