$icon-font__color: #a2a2a2;

//  Icon Font
$icons__font-path: '../fonts/MaterialIcons-Regular';
$icons__font-name: 'Material Icons';

// Content Overrides
// General
$icon-up: '\E316';
$icon-down: '\E313';
$icon-next: '\E315';
$icon-prev: '\E314';

$icon-arrow-up-thin: '\E316';
$icon-arrow-down-thin: '\E313';
$icon-arrow-right-thin: '\E315';
$icon-arrow-left-thin: '\E314';

$icon-arrow-up: '\E5D8';
$icon-arrow-down: '\E5DB';

$icon-pointer-up: '\E316';
$icon-pointer-right: '\E313';
$icon-pointer-left: '\E5C4';
$icon-pointer-down: '\E5DB';

$icon-envelope: '\E0BE';
$icon-grid: '\E5C3';
$icon-list: '\E8EF';
$icon-sorter: '\E164';

//Product Listing
$icon-wishlist-empty: '\E87E';
$icon-wishlist-full: '\E870';
$icon-compare-empty: '\E915';
$icon-compare-full: '\E915';

// Minicart
$icon-cart: '\E8CC';

// Account
$icon-account: '\E851';
$icon-account-round: '\E7FD';

// Wishlist
$icon-wishlist: '\E87E';

// Search
$icon-search: '\E8B6';

// Styling adjustments
// Breadcrumbs
$breadcrumbs-icon-font-size: 16px;
$breadcrumbs-icon-line-height: 16px;

$icon-warning: '\E002';
$icon-update: '\E5D5';
$icon-trash: '\E872';
$icon-star: '\E838';
$rating-icon__content: '\E838';
$rating-icon__font-size: 16px;
$rating-icon__letter-spacing: -3px;
$rating-icon__color: #e5e5e5;
$rating-icon__active__color: $polarcore-primary__color;
$icon-settings: '\E254';
$icon-menu: '\E5D2';
$icon-location: '\E0C8';
$icon-info: '\E88F';
$icon-comment-reflected: '\E0B9';
$icon-collapse: '\E3CE';
$icon-checkmark: '\E5CA';
$icon-calendar: '\E916';
$icon-remove: '\E5CD';
$icon-flag: '\E153';
$icon-expand: '\E5CF';
$icon-envelope: '\E158';
$icon-comment: '\E0B9';
$icon-help: '\E8FD';
$icon-gift-registry: '\E8F6';
$icon-present: '\e629';

