img {
    opacity: 1;
    transition: opacity 0.5s ease, transform 0.25s ease;
}

img[data-src] {
    opacity: 0;
    transform: scale(0.75);
    transition: opacity 0.5s ease, transform 0.25s ease;
}
