.product-item {
    position: relative;
}

.product.media {
    position: relative;
}

.tw-productlabels {
    display: grid;
    font-size: 0.8rem;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: column;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 5;
}

.products-grid {
    .tw-productlabels {
        left: 0.5rem;
        top: 0.5rem;
        width: calc(100% - 1rem);
    }
}

.product.media {
    .tw-productlabels {
        display: none;
    }

    .gallery-placeholder .tw-productlabels {
        display: grid;
    }
}

.tw-productlabels__label {
    grid-column: 1;
    margin: .25rem 0;
    text-align: left;
}

.tw-productlabels__text {
    background-color: $cblys__re-beige;
    display: inline-block;
    line-height: 1;
    padding: 0.25rem;
}

.tw-productlabels__label--discount {
    grid-column: 2;
    text-align: right;

    .tw-productlabels__text {
        color: white;
        background-color: $cblys__re-peach;
    }
}

.tw-productlabels__label--new .tw-productlabels__text {
    color: white;
    background-color: black;
}

@media only screen and (min-width: $screen__xs) {
    .tw-productlabels {
        font-size: 1rem;
    }

    .tw-productlabels__text {
        padding: 0.5rem;
    }
}
