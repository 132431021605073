#html-body .cblys-tooltip {
    position: relative;

    .tooltip-content {
        background-color: $cblys__white;
        border-radius: inherit;
        max-width: 360px;
        min-width: 270px;
        padding: 12px 16px 10px;
        z-index: 100;
        display: none;
        position: absolute;
        text-align: left;
        color: $cblys__black;
        font-family: inherit;
        font-style: inherit;
        font-weight: inherit;
        font-size: inherit;
        border: 1px solid $cblys__darkergray;
        margin-bottom: 3px;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
    }

    a.tooltip-toggle {
        text-decoration-line: underline;
        text-decoration-style: dotted;
        cursor: help;
    }

    a.tooltip-toggle:hover + .tooltip-content,
    a.tooltip-toggle:focus + .tooltip-content {
        display: block;
    }
}
