.table.grouped {
    @include lib-table-bordered($_table_type: horizontal);

    thead tr th.col {
        border: none;
    }

    tbody tr {
        border-top: 1px solid $cblys__slightgray;
    }

    .product-item-name {
        font-weight: $font-weight__regular;
    }

    .price-box {
        margin-bottom: 3px;

        .price {
            font-size: $font-size__base;
        }
    }

    .control {
        .qty {
            @extend .abs-input-qty;
        }
    }

    .row-tier-price {
        td {
            border-top: 0;
        }

        .prices-tier {
            margin: 0 -10px 10px;
        }
    }
}
