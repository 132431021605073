//
//  Variables
//  _____________________________________________

$active-nav-indent: 54px;
$nav-sections__margin-bottom: 0;

//
//  Common
//  _____________________________________________

.panel.header {
    .links,
    .switcher {
        display: none;
    }
}

.nav-sections {
    background: $navigation__background;
}

.nav-toggle {
    @include lib-icon-font(
        $icon-menu,
        $_icon-font-size       : 28px,
        $_icon-font-color      : $polarcore-nav-toggle__color,
        $_icon-font-color-hover: $polarcore-nav-toggle-hover__color
    );
    @include lib-icon-text-hide();
    cursor: pointer;
    display: block;
    font-size: 0;
    left: 15px;
    position: absolute;
    top: 15px;
    z-index: 14;
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m - 1) {
    .navigation {
        padding: 0;

        .level0.level-top {
            a, span {
                font-weight: 500;
                color: $polarcore-navigation-link-mobile__color;
            }
        }
        .parent {
            .level-top {
                position: relative;
                @include lib-icon-font(
                    $_icon-font-content : $icon-down,
                    $_icon-font-size    : 42px,
                    $_icon-font-position: after,
                    $_icon-font-display : block
                );

                &:after {
                    position: absolute;
                    right: 7px;
                    top: -8px;
                }

                &.ui-state-active {
                    @include lib-icon-font-symbol(
                        $_icon-font-content : $icon-up,
                        $_icon-font-position: after
                    );
                }
            }
        }
    }

    .nav-sections {
        -webkit-overflow-scrolling: touch;
        transition: left 0.3s;
        height: 100%;
        left: -80%;
        left: calc(-1 * (100% - #{$active-nav-indent}));
        overflow: auto;
        position: fixed;
        top: 0;
        width: 80%;
        width: calc(100% - #{$active-nav-indent});
        background-color: $polarcore-nav-sections-mobile__background-color;

        .switcher {
            font-size: 1rem;
            font-weight: 500;
            margin: 0;
            padding: 0.8rem 3.5rem 0.8rem 2rem;

            .label {
                display: block;
                margin-bottom: $indent__xs;
            }

            &:last-child {
                border-bottom: 1px solid $color-gray82;
            }
        }

        .switcher-trigger {
            .action {
                &:before {
                  display: none;
                }
                @include lib-icon-font(
                    $_icon-font-content : $icon-down,
                    $_icon-font-size    : 42px,
                    $_icon-font-position: after,
                    $_icon-font-display : block
                );
            }
            strong {
                position: relative;
                &:after {
                    position: absolute;
                    right: -40px;
                    top: -16px;
                }
            }

            &.active strong {
                @include lib-icon-font-symbol(
                    $_icon-font-content : $icon-up,
                    $_icon-font-position: after
                );
            }
        }

        .switcher-dropdown {
            @include lib-list-reset-styles();
            padding: $indent__s 0;
        }

        .header.links {
            @include lib-list-reset-styles();
            border-bottom: 1px solid $color-gray82;

            li {
                font-weight: 500;
                font-size: 1rem;
                margin: 0;

                &.greet.welcome {
                    //border-top: 1px solid $color-gray82;
                    font-weight: 400;
                    padding: 0.8rem $indent__base;
                }

                & > a {
                    border-top: 1px solid $color-gray82;
                }
            }

            a,
            a:hover {
                color: $navigation-level0-item__color;
                text-decoration: $navigation-level0-item__text-decoration;
                display: block;
                font-weight: 500;
                padding: 0.8rem $indent__base;
            }

            .header.links {
                border: 0;
            }
        }
    }

    .nav-before-open {
        height: 100%;
        overflow-x: hidden;
        width: 100%;

        .page-wrapper {
            transition: left 0.3s;
            height: 100%;
            left: 0;
            overflow: hidden;
            position: relative;
        }

        body {
            height: 100%;
            overflow: hidden;
            position: relative;
            width: 100%;
        }
    }

    .nav-open {
        .page-wrapper {
            left: 80%;
            left: calc(100% - #{$active-nav-indent});
        }

        .nav-sections {
            $_shadow: 0 0 5px 0 rgba(50, 50, 50, 0.75);

            box-shadow: $_shadow;
            left: 0;
            z-index: 99;
        }

        .nav-toggle {
            &:after {
                background: rgba(0, 0, 0, 0.7);
                content: '';
                display: block;
                height: 100%;
                position: fixed;
                right: 0;
                top: 0;
                width: 100%;
                z-index: 1;
            }
        }
    }

    .nav-sections-items {
        @include lib-clearfix();
        position: relative;
        z-index: 1;
    }

    .nav-sections-item-title {
        background: $polarcore-nav-sections-item-title__background;
        box-shadow: 1px 0px 0px 0px rgba(0, 0, 0, 0.15);
        box-sizing: border-box;
        float: left;
        height: 71px;
        padding-top: 24px;
        text-align: center;
        width: 33.33%;

        &.active {
            background: $polarcore-nav-sections-item-title-active__background;
            border-bottom: 0;
        }

        .nav-sections-item-switch {
            color: $polarcore-nav-sections-item-switch-mobile__color;
            &:hover {
                text-decoration: none;
            }
        }
    }

    .nav-sections-item-content {
        @include lib-clearfix();
        box-sizing: border-box;
        float: right;
        margin-left: -100%;
        margin-top: 71px;
        width: 100%;

        &.active {
            display: block;
        }

        padding: $indent__m 0;
    }

    @include lib-main-navigation($_nav-background-color: $navigation__background, $_nav-level0-item-color: $navigation__item-color);
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .nav-toggle {
        display: none;
    }

    .nav-sections {
        margin: $polarcore-nav-sections__margin;
        flex-shrink: 0;
        flex-basis: auto;
        margin-bottom: $nav-sections__margin-bottom;
    }

    .nav-sections-item-title {
        display: none;
    }

    .nav-sections-item-content {
        display: block !important;
    }

    .nav-sections-item-content > * {
        display: none;
    }

    .nav-sections-item-content {
        > .navigation {
            display: block;
        }
    }

    @include lib-main-navigation-desktop(
        $_nav-background-color: $navigation__background,
        $_nav-font-weight: $navigation__item-font-weight,
        $_nav-level0-item-margin: $navigation__level0-item-margin,
        $_nav-level0-item-color: $navigation__item-color,
        $_nav-level0-item-color-hover: $navigation__item-color-hover,
        $_nav-level0-item-background-color-active: $navigation__item-background-active,
        $_nav-level0-item-background-color-hover: $navigation__item-background-hover,
        $_nav-level0-item-color-active: $navigation__item-color-active,
        $_nav-level0-item__active__border-color: $navigation__item-border-color-active,
        $_nav-level0-item__active__border-width: $navigation__item-border-width-active,
        $_submenu-item__hover__background-color: $navigation__submenu-background-hover,
        $_submenu-border-color:$navigation__submenu-border-color,
        $_submenu-box-shadow: $navigation__submenu-box-shadow,
        $_submenu-parent-icon: $navigation__submenu-parent-icon,
        $_submenu-parent-icon-position: $navigation__submenu-parent-icon-position,
        $_submenu-parent-icon-color: $navigation__submenu-parent-icon-color,
        $_submenu-parent-icon-size: $navigation__submenu-parent-icon-size,
        $_submenu-arrow: $navigation__submenu-arrow,
        $_submenu-arrow-size: $navigation__submenu-arrow-size,
        $_submenu-arrow-left: $navigation__submenu-arrow-left
    );

    .navigation {
        li.ui-menu-item {
            margin: 0;
        }
        .submenu span {
            font-weight: 400;
        }
        .level0 .submenu.ui-menu a {
            display: flex;
            align-items: center;
            &:before {
                order: 1;
                font-size: 14px;
                margin-left: auto;
            }
        }
    }

    .panel.header {
        display: flex;
        .header.links {
          display: inline-block;
          .customer-welcome {
            .action.switch {
              color: inherit;
              vertical-align: top;
              &:before {
                font-size: 18px;
                line-height: 18px;
              }
            }
          }
        }
        .switcher {
            display: inline-block;
            margin-left: auto;
            line-height: 14px;
        }
        #switcher-language-trigger {
            font-size: $font-size__top-line;
            &:before {
                float: right;
                font-size: 18px;
                line-height: 17px;
            }
        }
    }
}
