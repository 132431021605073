$usp-burger-menu-color: #ecf0f1 !default;
.meganav__static-bottom, .footer__usp {
    display: block;
    background: $usp-burger-menu-color;
    color: $primary__color;
    padding-bottom: .5rem;
    font-size: 12px;

    ul {
        display: flex;
        list-style: none;
        justify-content: center;
        margin: 0;

        li {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: center;

            i {
                margin-right: 1rem;
            }

        }

        li + li {
            margin-left: 2rem;
        }
    }
}

.footer__usp {
    margin: -20px 0;
}
