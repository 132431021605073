//
//  Variables
//  ---------------------------------------------

$cblys-swatch-option-selected__border: 3px solid $cblys__green;

//
//  Swatches
//  _____________________________________________

.clearfix:after {
    content: '';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
}

.swatch-attribute-label {
    @include cblys-overline();

    &.required {
        padding-right: 10px;
    }

    &[data-required="1"]::after {
        content: '*';
        color: red;
        position: absolute;
        right: -11px;
        top: -2px;
        font-weight: bold;
        font-size: 1em;
    }
}

.swatch-attribute-selected-option {
    display: none;
}

.swatch-attribute-options {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0 auto 10px;
    max-width: 350px;
}

.swatch-option {
    @include cblys-swatch-button;

    &.selected {
        border: $cblys-swatch-option-selected__border;
    }

    &.color,
    &.image {
        width: 24px;
        height: 24px;
        width: 24px;
        min-width: 24px;
        max-width: 24px;
        height: 24px;
        max-height: 24px;
        min-height: 24px;
        border: 3px solid rgb(218, 218, 218);
        border-radius: 50%;
        &.selected {
            border: $cblys-swatch-option-selected__border;
            color: #333;
        }
        &:not(.disabled):hover {
            outline: 0;
            border: $cblys-swatch-option-selected__border;
            opacity: 0.8;
        }
    }

    &.disabled {
        cursor: default;
        &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: linear-gradient(
                to left top,
                rgba(255, 255, 255, 0) 0%,
                rgba(255, 255, 255, 0) 42%,
                rgba(255, 255, 255, 1) 43%,
                rgba(255, 255, 255, 1) 46%,
                rgba(255, 82, 22, 1) 47%,
                rgba(255, 82, 22, 1) 53%,
                rgba(255, 255, 255, 1) 54%,
                rgba(255, 255, 255, 1) 57%,
                rgba(255, 255, 255, 0) 58%,
                rgba(255, 255, 255, 0) 100%
            );
        }
    }
    .sale {
        color: $cblys__red;
        padding-left: 0.5rem;
    }
}

.swatch-attribute {
    &.manufacturer,
    &.size {
        .swatch-option {
            background: #ffffff;
            color: rgb(148, 148, 148);
            &.selected {
                color: black;
                background: #f3f3f3;
                border: 1px solid #f0f0f0;
            }
        }
    }
}

.swatch-option-tooltip {
    display: none;
    z-index: 999;
    max-width: 140px;
    max-height: 100%;
    min-height: 20px;
    min-width: 20px;
    position: absolute;
    padding: 5px;
    color: rgb(148, 148, 148);
    text-align: center;
}

.swatch-option-tooltip,
.swatch-option-tooltip-layered {
    .corner {
        left: 40%;
        position: absolute;
        bottom: -1rem;
        height: 8px;
        &::before {
            content: '';
            position: relative;
            top: 2px;
            left: 2px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 8px 8.5px 0 8.5px;
            border-color: #e0e0e0 transparent transparent transparent;
            font-size: 1px;
        }
        &::after {
            content: '';
            position: relative;
            top: 1px;
            left: -14px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 7px 7.5px 0 7.5px;
            border-color: #fff transparent transparent transparent;
            font-size: 1px;
        }
    }
    .image {
        display: block;
        position: relative;
        bottom: -0.75rem;
        height: 64px!important;
        width: 64px!important;
        border-radius: 50%;
        border: $polarcore-swatch-option-tooltip-image__border; // 5px solid #blue
        margin: 0 auto;

    }
}

.swatch-option-tooltip .title {
    display: block;
    position: relative;
    bottom: -19px;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    padding: 0.5rem 1rem;
    overflow: hidden;
    max-width: 140px;
    min-height: 20px;
    max-height: 200px;
    color: rgb(40, 40, 40);
    text-align: center;
}

.swatch-opt {
    margin: 20px 0;
}

.swatch-more {
    display: inline-block;
    margin: 2px 0;
    text-decoration: none !important;
    position: static;
    z-index: 1;
}

// Layered Features
.swatch-option-link-layered {
    position: relative;
    margin: 0 !important;
    padding: 0 !important;
}

.swatch-option-tooltip-layered {
    width: 140px;
    position: absolute;
    background: #fff;
    color: rgb(148, 148, 148);
    border: 1px solid #adadad;
    display: none;
    z-index: 999;
    left: -47px;
}

.swatch-visual-tooltip-layered {
    height: 160px;
    top: -170px;
}

.swatch-textual-tooltip-layered {
    height: 30px;
    top: -40px;
}

.swatch-option-link-layered:hover > .swatch-option-tooltip-layered {
    display: block;
}

.swatch-attribute-options .swatch-option-tooltip-layered .title {
    width: 100%;
    height: 20px;
    position: absolute;
    bottom: -5px;
    left: 0;
    color: rgb(40, 40, 40);
    text-align: center;
    margin-bottom: 10px;
}

.swatch-option-disabled:after {
    content: '';
    position: absolute;
    width: 42px;
    height: 2px;
    background: red;
    transform: rotate(-30deg);
    z-index: 995;
    left: -4px;
    top: 10px;
}

.swatch-option-disabled {
    outline: none !important;
    cursor: default;
    border: 0;
}

// Bugfix for Add To Cart button
.swatch-opt-listing {
    margin-bottom: 10px;
}
.swatch-option-loading {
    content: url(../Magento_Swatches/images/loader-2.gif);
}

// Magento 2.2 fix input
// https://github.com/magento/magento2/blob/2.2.0/app/code/Magento/Swatches/view/frontend/web/css/swatches.css

.swatch-input {
    left: -1000px;
    position: absolute;
    visibility: hidden;
}

