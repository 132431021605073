//
//  Button with solid or picture background
//  ---------------------------------------------
$button-icon__position              : $icon__position !default;
$button-icon__font                  : $icon-font !default;
$button-icon__font-size             : 22px !default;
$button-icon__line-height           : $button-icon__font-size !default;
$button-icon__margin                : 0 !default;
$button-icon__color                 : inherit !default;
$button-icon__hover__font-color     : inherit !default;
$button-icon__active__font-color    : inherit !default;

$button__font-family                : $font-family__base !default;
$button__font-size                  : $font-size__base !default;
$button__font-weight                : $font-weight__semibold !default;
$button__line-height                : $line-height__base !default;

//  Default = secondary button
$button__padding                    : 7px 15px !default;
$button__color                      : $primary__color !default;
$button__background                 : $color-gray95 !default;
$button__border                     : 1px solid $color-gray-darken2 !default;
$button__border-radius              : 3px !default;
$button__hover__color               : $color-gray-darken3 !default;
$button__hover__background          : $color-gray-darken1 !default;
$button__active__background         : $button__color !default;

//  Primary button
$button-primary__padding            : $button__padding !default;
$button-primary__color              : $color-white !default;
$button-primary__background         : $color-blue1 !default;
$button-primary__border             : 1px solid $button-primary__background !default;
$button-primary__hover__color       : $color-white !default;
$button-primary__hover__background  : $color-blue2 !default;
$button-primary__hover__border      : 1px solid $button-primary__background !default;
$button-primary__active__background : $link__hover__color !default;
$button-primary__active__border     : 1px solid $link__hover__color !default;
$button-primary__active__color      : $color-white !default;

$button__shadow                     : none !default;
$button__shadow-active              : none !default;

//  Large button
$button__font-size__l               : $font-size__l !default;
$button__line-height__l             : $font-size__l + 4 !default;
$button__padding__l                 : 14px 17px !default;

//  Small button
$button__font-size__s               : 11px !default;
$button__line-height__s             : $button__font-size__s + 1 !default;
$button__padding__s                 : $indent__xs 8px !default;
