@mixin cblys-modal() {
    .modal {
        &-slide {
            left: $modal-slide__first__indent-left;
            @media (min-width: $screen__l) {
                left: $modal-slide__first__indent-left-desktop;
            }

            &._show {
                background: none;
            }

            .action-close {
                padding: 30px 32px;
                display: flex;
                flex-direction: row-reverse;
                align-items: center;

                &::before {
                    font-size: 23px;
                    line-height: 23px;
                }

                & > span {
                    clip: auto;
                    height: auto;
                    width: auto;
                    position: relative;
                    @include cblys-overline();

                }
            }
        }

        &-title {
            text-align: left;
            margin-bottom: 0;
        }

        &-header {
            padding-bottom: 0;
        }
    }
}

.modals {
    &-wrapper {
        @include cblys-modal();
    }

    &-overlay {
        background: rgba($primary__color, 0.5);
    }
}
