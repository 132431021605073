.fotorama__arr {
    @extend .fotorama-no-select;
    @extend .fotorama-no-tap;
    position: absolute;
    top: 0;
    bottom: 0;
    width: $fotorama-arw-size;
    box-sizing: border-box;
    z-index: $z-index-9;
    cursor: pointer;
    &:focus {
        box-shadow: none;
        &:after {
            border-radius: inherit;
            bottom: $fotorama-inner-box-shadow;
            box-shadow: $focus__box-shadow;
            content: '';
            left: $fotorama-inner-box-shadow;
            position: absolute;
            right: $fotorama-inner-box-shadow;
            top: $fotorama-inner-box-shadow;
            z-index: $z-index-10;
        }
    }

    &--prev {
        left: 0;
        .fotorama__wrap--css3.fotorama__wrap--no-controls.fotorama__wrap--slide.fotorama__wrap--toggle-arrows &:not(:focus) {
            transform: translate3d(-$fotorama-arw-size * 1.5, 0, 0);
        }

        .fotorama__wrap--css3.fotorama__wrap--video & {
            transform: translate3d(-$fotorama-arw-size * 1.5, 0, 0);
        }
    }

    &--next {
        right: 0;
        .fotorama__wrap--css3.fotorama__wrap--no-controls.fotorama__wrap--slide.fotorama__wrap--toggle-arrows &:not(:focus) {
            transform: translate3d($fotorama-arw-size * 1.5, 0, 0);
        }
        .fotorama__wrap--css3.fotorama__wrap--video & {
            transform: translate3d($fotorama-arw-size * 1.5, 0, 0);
        }
    }

    &--disabled {
        display: none;
        opacity: 0.1;
        pointer-events: none;
        cursor: default;
    }

    .fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows & {
        opacity: 0;
        &:focus {
            opacity: 1;
        }
    }

    .fotorama__wrap--video & {
        opacity: 0 !important;
    }

    .fotorama__wrap--css2.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows & {
        display: none;
        &:focus {
            display: block;
        }
    }

    .fotorama__wrap--css2.fotorama__wrap--video & {
        display: none !important;
    }

    .fotorama__wrap--css3 &:not(:focus) {
        transition-duration: $fotorama-duration-time;
        transition-property: transform, opacity, background-color;
    }

    .fotorama__stage &--shown {
        display: block !important;
    }

    .fotorama__stage &--hidden {
        display: none !important;
    }
}
