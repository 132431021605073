@mixin icon() {
}

@mixin input() {
    height: 36px;

    &::placeholder {
        color: $primary__color;
    }
}

@mixin link-standard {
    text-decoration: underline;

    &:hover, &:focus {
        text-decoration: none;
    }
}
