.breadcrumbs {
    margin: auto;
    padding-bottom: 20px;
    width: 100%;
    max-width: $layout__max-width;
    overflow: visible;

    .items {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        padding: 0;
        margin: 0;
        justify-content: flex-start;
    }

    .item {
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.05em;
        display: flex;
        align-items: center;

        &:not(:last-child):after {
            @include material-icon("keyboard_arrow_right");
            font-size: 18px;
            margin: 0 5px;
        }
    }

    .catalog-product-view & {
        order: -2;
        padding-bottom: 0;
        width: 100%;
    }
}

@include max-screen($screen__m) {
    .catalog-category-view .breadcrumbs .items {
        justify-content: center;
    }

    .catalog-product-view .breadcrumbs {
        order: 0;
        padding-bottom: 16px;

        .items > :last-child {
            display: none;
        }

        .items > :nth-last-child(2)::after {
            display: none;
        }
    }
}
