//
//  Variables
//  _____________________________________________

$checkout-wrapper__margin: $indent__base !default;
$checkout-wrapper__columns: 8 !default;

$checkout-step-title__border: $border-width__base solid $color-gray80 !default;
$checkout-step-title__font-size: 26px !default;
$checkout-step-title__font-weight: $font-weight__light !default;
$checkout-step-title__padding: $indent__s !default;

$checkout-step-title-mobile__font-size: 18px !default;
$checkout__layout-width: 630px;

//
//  Common
//  _____________________________________________

.checkout {
    &-klarna-index {
        .page {
            &-title {
                @include cblys-title(30px);
                margin: 0;
            }

            &-title-wrapper {
                text-align: center;
            }

            &-header {
                border: none;
            }

            &-main {
                max-width: 630px;
            }
        }

        header .header.content {
            & > .nav-toggle,
            .nav-sections {
                display: none;
            }

            .logo {
                position: relative;
                left: auto;
                transform: none;
            }
        }
    }

    &-klarna-success {
        #registration {
            margin: 0 1rem;
            .action-primary {
                margin: 0 1.6rem 0 auto;
            }
        }
        .print {
            margin-left: auto;
        }
    }

    &-container {
        @extend .abs-add-clearfix;
    }

    &__back-link {
        @include cblys-overline(14px);
        border-bottom: 1px solid;
    }
    #maincontent {
        .page-title-wrapper {
            text-align: center;
            padding: 1rem;
        }

        #klarna_checkout_success,
        .checkout-success {
            max-width: $checkout__layout-width;
            margin: auto;
        }
    }
}

.opc-wrapper {
    margin: 0 0 $checkout-wrapper__margin;

    .opc {
        @extend .abs-reset-list;
    }

    .step-title {
        @extend .abs-checkout-title;
    }

    .step-content {
        margin: 0 0 $indent__xl;
    }
}

#klarna-checkout-container {
    width: 100%;
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .page-header {
        padding-bottom: 1rem;
    }

    #html-body.checkout-klarna-index {
        .checkout__back-link-wrapper {
            justify-content: center;
            margin-top: 8px;
        }
    }
}
