// Mixins
@import 'mixins/modals';

/*
 * Components
 */
@import 'components/animated-image-loading';
@import 'components/images';
@import 'components/trollweb-meganav';
@import 'components/trollweb-categoryboxes';
@import 'components/sticky-header';
@import 'components/cblys-icons';
@import 'components/sidebar-toolbar';
@import 'components/smile-price-slider';
@import 'components/click-and-collect';
@import 'components/visma-color';
@import 'components/ups';
@import 'components/category-hero';
@import 'components/energy-label';

/*
 * For small adjustments only
 * use overrides if you want to make major changes
 */
@import 'cblys/general';
@import "cblys/fonts";
@import 'cblys/footer';
@import 'cblys/header';
@import 'cblys/header-panel';
@import 'cblys/newsletter';
@import 'cblys/usplist';
@import "cblys/storefinder";
@import "cblys/modals";
@import "cblys/elasticsearch";
@import "cblys/pagebuilder";

@import "../../Trollweb_ProductLabels/styles/module";
@import "../../CBLys_Tooltip/styles/module";
@import "../../CBLys_SuccessPage/styles/module";
@import "../../CBLys_StickyAddToCart/styles/module";
