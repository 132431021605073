.sticky-addtocart-wrapper {
    position: fixed;
    bottom: -100%;
    left: 0;
    right: 0;
    background-color: $cblys__white;
    z-index: 2;
    padding: 1rem 0;
    transition: bottom 0.5s;

    &.show {
        bottom: 0;
    }
}

.sticky-addtocart {
    display: grid;
    grid-template-areas:
        "details price"
        "actions actions";
    grid-template-columns: 1fr auto;
    column-gap: 1rem;
    row-gap: 1rem;
    align-items: center;
    max-width: 1280px;
    margin: 0 auto;
    padding-left: 1rem;
    padding-right: 1rem;

    @include min-screen($screen__m) {
        grid-template-areas:
            "image details price actions";
        grid-template-columns: auto 2fr 1fr 200px;
    }

    @include min-screen($screen__l) {
        grid-template-columns: auto 3fr 1fr 350px;
    }

    &__image {
        display: none;

        @include min-screen($screen__m) {
            display: block;
            grid-area: image;

            img {
                display: block;
                width: 90px;
                height: 130px;
                border-radius: 20px;
                box-shadow: 4.0px 8.0px 8.0px #{'hsl(0deg 0% 0% / 0.38)'};
            }
        }
    }

    &__details {
        grid-area: details;
        font-weight: 700;

        @include min-screen($screen__m) {
            font-size: (26rem/14);
            font-family: $font-family__header;
            font-weight: normal;
        }
    }

    &__price {
        grid-area: price;
        justify-self: end;

        /* Most styling for prices comes from Magento_Catalog/styles/module/_common.scss */
        .price-box {
            .price {
                @include cblys-price-style2(1.5rem);
                line-height: 1;

                @include min-screen($screen__m) {
                    font-size: 2rem;
                }
            }

            .custom-price-box.with-advertised-price .special-price {
                font-size: unset;
            }

            .old-price .price-container {
                white-space: nowrap;
            }

            .custom-price-box.with-advertised-price .old-price .price {
                @include cblys-price-style2(1rem);
            }

            .custom-price-box.with-discount {
                flex-direction: column;
                align-items: end;

                .old-price {
                    margin: 0;

                    .price {
                        font-size: 1.15rem;
                    }
                }
            }
        }
    }

    &__actions {
        grid-area: actions;
        display: flex;
        flex-direction: row;
        column-gap: 1rem;
        row-gap: 1rem;

        @include min-screen($screen__m) {
            flex-direction: column;
        }

        @include min-screen($screen__l) {
            flex-direction: row;
        }

        button {
            @include cblys-re-green-btn;
            padding: 1.2rem 2rem;
            min-width: calc(50% - 1rem);
            display: none;
            border-radius: 20px;
        }

        button.cac {
            background-color: $cblys__beige;

            &:hover {
                background-color: $cblys__beige-main;
            }
        }
    }
}

.sticky-cart-shown {
    .product.data.items {
        z-index: unset;
    }

    .product-messages {
        position: fixed;
        bottom: 135px;
        z-index: 10005;
        left: 0;
        right: 0;

        @include min-screen($screen__m) {
            bottom: 150px;
        }

        .page.messages {
            max-width: 700px;

            div.message {
                box-shadow: 0 0 15px 10px rgba(255, 255, 255, .75);
            }
        }
    }
}
