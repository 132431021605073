@mixin cblys-base-title($font-size, $font-family) {
    font-family: $font-family;
    font-style: normal;
    font-weight: bold;
    font-size: $font-size;
    line-height: $font-size * 1.25;
}

@mixin cblys-subtitle() {
    @include cblys-base-title(16px, $font-family__base);
}

@mixin cblys-body-text($font-size) {
    font-family: $font-family__base;
    font-style: normal;
    font-size: $font-size;
    line-height: $font-size * 1.25;
}

@mixin cblys-caption() {
    @include cblys-body-text(12px);
    letter-spacing: 0.05em;
}

@mixin cblys-overline($font-size: 10px) {
    @include cblys-body-text($font-size);
    text-transform: uppercase;
}

@mixin cblys-title($font-size, $desktop-font-size: $font-size) {
    @include cblys-base-title($font-size, $font-family__header);
    @include min-screen($screen__m) {
        @include cblys-base-title($desktop-font-size, $font-family__header);
    }
}

@mixin cblys-text($font-size, $desktop-font-size: $font-size) {
    @include cblys-body-text($font-size);
    @include min-screen($screen__m) {
        @include cblys-body-text($desktop-font-size);
    }
}

@mixin cblys-block-title-small($_block-title-small__font-size: 10px) {
    font-size: $_block-title-small__font-size;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 1.2em;
    letter-spacing: 0.15em;
    color: $cblys__black;
}

/*
 * CBLYS Typography
 */

h1 {
    @include cblys-title(32px, 52px);
}

h2,
.p2-category-top .page-title {
    @include cblys-title(32px, 42px);
}

h3 {
    @include cblys-title(24px, 34px);
}

h4 {
    @include cblys-title(18px, 28px);
}

h5 {
    @include cblys-title(18px, 24px);
}

h6 {
    @include cblys-title(16px, 20px);
}

.app-container .top-message {
    @include cblys-overline();
}

.app-container .store-list-item-button span {
    @include cblys-overline(12px);
}
