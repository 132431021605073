body .app-container {
    width: auto!important;
    padding: 0;
}
.app-container {
    .list-card,
    .store-list-item-button {
        text-transform: none;
    }
    .store-list {
        &-item {
            &-button {
                max-width: none;
            }

            &-directionsIcon {
                color: $cblys__black;
            }

            &-name {
                color: $cblys__re-brown;
            }
        }
        &-left {
            max-width: none;
        }
    }
    .icon-button-container {
        color: $cblys__black!important;
        &.icon-button-active {
            background: $cblys__black!important;
            color: $cblys__white!important;
        }
    }
    .location-button-text {
        color: $cblys__black;
    }
    .list-card {
        box-shadow: none;
        border-radius: $cblys__border-radius;
        border: 1px solid $cblys__black;
    }
    #map {
        box-shadow: none;
    }
    .back-button {
        border: 1px solid $cblys__black!important;
        border-radius: $cblys__border-radius;
        margin-left: 10px;
        padding: 10px 5px;
        &-text {
            @include cblys-overline(12px);
            margin: 0 20px;
        }
    }
}
@include min-screen($screen__m) {
    .app-container {
        .location-button-container {
            width: 100%;
        }
    }
}
