.cblys__newsletter {
    display: flex;
    flex-flow: column wrap;
    max-width: $layout__max-width;
    color: $primary__color;
    padding: 0;

    .block {
        flex-grow: 1;

        &.newsletter {
            margin: 0 0 2rem;

            .form.subscribe {
                display: flex;
                flex-flow: column wrap;

                .field.newsletter #newsletter {
                    @include input();
                }

                .actions {
                    width: 100%;
                    margin-top: 1rem;

                    .subscribe {
                        @include cblys-re-green-btn;
                        color: $primary__color;
                    }

                    .container {
                        display: flex;
                        align-items: center;
                        position: relative;
                        margin: 12px 0;
                        cursor: pointer;
                        user-select: none;
                        font-size: 10px;

                        input {
                            position: absolute;
                            opacity: 0;
                            cursor: pointer;
                            height: 0;
                            width: 0;

                            &:checked ~ .checkmark {
                                background-color: $primary__color;

                                &::after {
                                    display: block;
                                }
                            }
                        }

                        .checkmark:after {
                            content: "check";
                            font-family: "Material Icons";
                            color: #fff;
                            font-size: 18px;
                        }

                        a {
                            color: #6f9dde;
                            text-decoration: underline;
                            margin-left: .2rem;
                        }
                    }

                    .checkmark {
                        order: -1;
                        height: 18px;
                        width: 18px;
                        border-radius: 3px;
                        background-color: #eee;
                        display: flex;
                        align-items: center;
                        margin-right: .5rem;

                        &::after {
                            content: "";
                            position: absolute;
                            display: none;
                        }
                    }
                }
            }
        }

        .newsletter {
            &__header {
                font-size: 1.6rem;
            }

            &__paragraph {
            }
        }
    }
}

@include min-screen($screen__m) {
    .cblys__newsletter {
        flex-flow: row nowrap;

        .block {
            max-width: 50%;

            &.newsletter {
                margin-bottom: 0;
                align-items: center;
                display: flex;
            }

            .newsletter {
                &__paragraph {
                    padding-right: 1rem;
                }
            }
        }
    }
}

@include min-screen($screen__l) {
    .cblys__newsletter {
        .block {
            &.newsletter {
                .form.subscribe {
                    flex-flow: row nowrap;

                    .newsletter {
                        flex-grow: 1;
                    }

                    .actions {
                        width: auto;
                        margin-top: 0;
                        margin-left: 1rem;

                        .action {
                            span {
                                text-transform: none;
                                font-weight: 500;
                            }
                        }
                    }
                }
            }

            .newsletter {
                &__header {
                    font-size: 34px;
                }

                &__paragraph {
                    line-height: 24px;
                }
            }
        }
    }
}

@include max-screen($screen__m) {
    .cblys__newsletter {
        display: none;
    }
}
