// Fonts
$font-family__base: "AvenirNextLTPro", Helvetica, sans-serif;
$font-family__header: "Old Standard TT";

$cblys__border-radius: 0;

// Header Panel
$header-panel__background-color: $cblys__gray;
$header-panel__text-color: $primary__color;

// Navigation
$navigation__background: $cblys__white;
$navigation__level0-item-margin: 0;
$navigation__item-color: $primary__color;
$navigation__item-color-hover: $primary__color;
$navigation__item-color-active: $primary__color;
$navigation__item-font-weight: 300;
$navigation__item-background-hover: darken($navigation__background, 50%);
$navigation__item-background-active: darken($navigation__background, 50%);
$navigation__item-border-color-active: darken($navigation__background, 50%);

// dosent work?
$_nav-level0-item__active__border-width: 1px;
$_nav-level0-item-border: 0px;
$_nav-border: 0;

$navigation__submenu-background-hover: $secondary__color;
$navigation__submenu-border-color: rgba(0, 0, 0, 0.05);
$navigation__submenu-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);

// Body
$page__background-color: $cblys__lightgray2;

// Footer
$footer__background-color: $primary__color;
$footer-links-color: $secondary__color;
$footer-links-color-current: $primary__color;
$footer-links-color-hover: $primary__color;

// Copyright footer
$copyright__background-color: $secondary__color;
$copyright__text-color: black;

// color links
$product-name-link__color: $primary__color;
$product-name-link__text-decoration: none;
$product-name-link__color__visited: $primary__color;
$product-name-link__color__hover: $primary__color;
$product-name-link__color__active: $primary__color;
$link__color: $primary__color;
$link__visited__color: $link__color;
$link__hover__color: $link__color;
$link__active__color: $link__color;

// Review
$rating-icon__active__color: $primary__color;
$_icon-color-active: $primary__color;

// Querys
$layout__max-width: 1280px;

// Modals
$modal-slide__first__indent-left: 20%;
$modal-slide__first__indent-left-desktop: 70%;
