$search-autocomplete__border-bottom-color: #ededed !default;
$search-autocomplete__background-color: $cblys__white !default;
$search-autocomplete__focus-background: $cblys__beige !default;

.block-search .block-content {
    margin-left: 0;
    width: 100%;
    text-align: left;

    #search_mini_form {
        .field.search {
            .control {
                #search_autocomplete {
                    left: 0;
                    padding-bottom: 0;
                    position: absolute;
                }
            }
        }
    }
}

#search_autocomplete {
    background: $search-autocomplete__background-color;
    z-index: 100;
    margin-top: 2px;

    .autocomplete-list {
        margin-bottom: 0;

        .autocomplete-list-title {
            font-size: 18px;
            line-height: 28px;
            font-weight: bolder;
            margin-bottom: 8px;
            margin-top: 16px;
        }

        #qs-option-0 .qs-option-name {
            padding-right: 8px;
        }

        .qs-option-name {
            padding-right: 1rem;
        }

        .category-mini-crumb {
            padding-right: 0.5rem;
            color: $cblys__darkbrown;
        }

        .amount {
            padding-right: 2rem;
        }

        dd {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 8px 0px 8px 16px;
            cursor: pointer;
            margin-bottom: initial;

            &.selected {
                &:hover {
                    background: $search-autocomplete__focus-background;
                }

                .product-name {
                    color: $cblys__black-shade;
                }

                .price-container .price {
                    color: $cblys__darkbrown-shade;
                }
            }

            .product-image-box {
                width: 45px;
                height: 60px;
                margin-right: 16px;
            }
        }

        .product-item {
            border-bottom: none;
            width: 80%;
            max-width: 100%;

            .product-name {
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;
                margin-bottom: 8px;
            }

            .price-container .price {
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;
                color: $cblys__darkbrown;
            }

            .custom-price-box.with-discount .special-price .price {
                color: $cblys__red;
            }
        }

        .close-button {
            &__wrapper {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
            }
        }
    }

    .show-result-link {
        position: absolute;
        top: 0.25rem;
        right: 0;
        margin: 0;
        padding: 0;

        &::before {
            display: none;
        }

        .show-result-button {
            border-color: $cblys__pear;
            width: 100%;
            height: 100%;
            margin: 0 1.25rem;
            padding: 1.15rem 0;
            pointer-events: auto;

            &:hover {
                box-shadow: none;
                background-color: $cblys__beige-main;
                border-color: $cblys__beige-main;
            }
        }

        &:hover {
            text-decoration: none;
        }
    }

    .autocomplete-list-title {
        font-size: 1.4rem;
        padding-left: 1rem;
    }

    .search.close-button {
        position: absolute;
        right: 0.5rem;
        top: 0.5rem;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .block-search .block-content {
        top: 64px;
        background-color: $cblys__lightgray2;
        border-bottom: none;
        margin: 8px 0 0 0;

        #search_mini_form {
            .field.search {
                .control {
                    #search_autocomplete {
                        left: -8px;
                        width: 359px;
                        position: absolute;
                        background-color: none;
                        margin: 8px;

                        .search.close-button {
                            right: 17px;
                            top: 18px;
                        }

                        .smile-elasticsuite-autocomplete-result {
                            background-color: $cblys__white;
                        }
                    }
                }
            }
        }
    }

    .show-result-link .show-result-button {
        width: 95.7%;
    }
}

@include screen($screen__m, $screen__l) {
    .block-search .block-content {
        top: 88px;
        background-color: $cblys__lightgray2;
        border-bottom: none;
        margin: 8px 0 0 0;
        left: 0;
        max-width: unset;
        padding: 8px 16px;

        #search_mini_form {
            .field.search {
                .control {
                    #search_autocomplete {
                        left: 0;
                        margin: 0 15px;
                        width: 96.3% !important;
                        position: absolute;

                        .search.close-button {
                            right: 12px;
                            top: 18px;
                        }

                        .show-result-link .show-result-button {
                            width: 95.7%;
                        }
                    }
                }
            }
        }
    }
}

@include min-screen($screen__l) {
    #search_autocomplete {
        width: 400px !important;
    }
    .search-autocomplete {
        overflow-y: auto;
    }

    .search.close-button {
        display: none;
    }
}
