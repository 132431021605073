header {
    .sticky-logo {
        display: none;
    }

    .header.content {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;

        & > div,
        & > a:not(.logo) {
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 100;
            padding: 6px 12px;
            width: 64px;
            height: 60px;
        }

        & > .nav-toggle {
            @include lib-icon-font(
                $icon-menu,
                $_icon-font-size: 16px,
                $_icon-font-color: $polarcore-nav-toggle__color,
                $_icon-font-color-hover: $polarcore-nav-toggle-hover__color
            );
            order: -1;
            left: 0;
            top: 0;
            position: relative;
            transform: scale(1);
            display: flex;

            &:before {
                border-radius: 0;
                font-weight: bold;
            }
        }

        & > .logo {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }

        .cblys-header-item__wrapper {
            padding: 7.5px 12px;

            .icon-user {
                display: flex;
                justify-content: center;
                flex-direction: column;
                margin: 0 0 5px 0;

                &::before {
                    margin-right: 0;
                    margin-top: 5px;
                    font-size: 24px;
                }

                &:hover {
                    text-decoration: none;
                }
            }

            &:hover {
                text-decoration: none;
            }

            span {
                font-size: 13px;
                line-height: 16px;
                letter-spacing: 0.5px;
                width: auto;
                height: auto;
                position: static;
                clip: unset;
            }
        }

        #cac-cart-trigger .cac-trigger-wrapper,
        .action.showcart {
            &:before {
                margin-bottom: 6px;
                font-weight: bold;
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    header.page-header {
        padding: 0;

        .header.content {
            padding: 24px 16px;

            .nav-toggle {
                align-items: center;
                width: 40px;
                height: 40px;
            }

            & > div,
            & > a:not(.logo) {
                padding: 0px;
                width: 40px;
                height: 40px;
            }

            .block.block-search {
                margin-left: 0;
                margin-top: -3px;
            }

            .main-logo {
                display: none;
            }

            .cblys-header-item__wrapper {
                display: none;
            }

            #cac-cart-trigger {
                padding: 0;
                margin: 0 10px;

                .cac-trigger-wrapper:before {
                    margin-bottom: 0px;
                }
            }
        }
    }
}

//
//  Tablet
//  _____________________________________________

@include min-screen($screen__m) {
    header .header.content {
        position: relative;
        padding-top: 2rem;
        padding-bottom: 2rem;

        .mobile-logo {
            display: none;
        }

        .cblys-header-item__wrapper,
        #cac-cart-trigger,
        .minicart-wrapper {
            &:hover {
                text-decoration: none;
                background-color: $cblys__white;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__l) {
    header {
        .header.content {
            & > .nav-toggle {
                display: none;
            }
        }
    }
}
