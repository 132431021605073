.category-quicklinks {
    margin: 0 0 30px 0;

    @include max-screen($screen__m) {
        margin: 0 1em 30px 1em;
    }

    &__list {
        display: grid;
        grid-template-columns: repeat(6,1fr);
        grid-gap: 15px;
        margin: 0;
        padding: 0;
        list-style: none;

        @include max-screen($screen__l) {
            grid-template-columns: repeat(4,1fr);
        }

        @include max-screen($screen__m) {
            grid-template-columns: repeat(2,1fr);
            grid-gap: 10px;
        }
    }

    &__item {
        margin: 0;
        overflow: hidden;
        border: 1px solid $cblys__re-light-brown;
        display: flex;
    }

    &__link {
        display: flex;
        padding: 1rem;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        min-height: 48px;
        background-color: $cblys__white;

        .icon {
            width: 24px;
        }

        &.active,
        &:hover {
            background-color: $cblys__re-light-brown;
            color: $cblys__white;
            text-decoration: none;

            .icon {
                filter: grayscale(1) brightness(100);
            }
        }
    }

    &__title {
        flex-grow: 1;
        text-align: center;
        font-size: 15px;
        line-height: 1;

        @include max-screen($screen__m) {
            font-size: 14px;
            font-weight: 400;
        }
    }
}
